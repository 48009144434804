function settime_to_hide(){
    setTimeout(function() {
      $('.alert').hide();
    }, 10000);
}
 settime_to_hide()
$(window).on("popstate", function(e) {
    var state = e.originalEvent.state;
    //console.log(e.target.location.href)

      //window.location.href = e.target.location.href

});
// announcement js //

$(document).on('click', '.view_property_announcements_popup', function (e) {
  e.preventDefault();
  // console.log("got hit")
  var property_id = $(this).attr('data-announcement_id')
  $.ajax({
    type: "get",
    url: "/view_announcement_detail",
    data:{property_id: property_id},
    headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
    },
    success: function(response){
      if(response.status){
        $(document).find("#show_announcement_modal").modal('show')
        $(document).find(".show_announcement_modal_body").html(response.data)
        validate_announcement()
        //$('#property_announcement_schedule_date').datetimepicker();
         //alert("modal")
      }
    }
  })
})
$(document).on("submit","#save_agent_contact_form",function(e){
  e.preventDefault()
  $(document).find(".loader-outer").removeClass("d-none")
  $(document).find(".loader-outer").addClass("d-block")
  console.log($("form#save_agent_contact_form").valid())
 if($("form#save_agent_contact_form").valid()){
    $.ajax({
      url: "/agent/save_agent_contact",
      type: "post",
      data: $(this).closest("form").serialize(),
      headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
      success: function(response){
        if(response['status']){
          $(document).find("#agent_full_name").val('')
          $(document).find("#agent_email").val('')
          $(document).find("#agent_phone_number").val('')
          $(document).find("#agent_messages").val('')
          $(document).find('#agent_done').modal('show')
        }else{
          $('#ajax_flash').show().html("").html('<div class="slide-effect"><div class="alert alert-danger alert-dismissible"><button type="button" class="close" data-dismiss="alert" aria-hidden="true"><i class="fas fa-times"></i></button>  <div class="alert-message p-med"><i class=" fa fa-ban"></i> Alert!</div>'+response["message"]+' </div></div>');
        }
        $(document).find(".loader-outer").addClass("d-none")
        $(document).find(".loader-outer").removeClass("d-block")
      }
    })
  }
})

// $(document).on('click',"#nav-rent-tab",function(e){
//   console.log($('#rent-slider'))
//   $('#rent-slider').slick("unslick");
//   //$('#rent-slider').slick("unslick");
//   $('.rent-slider').slick({
//     dots: false,
//     infinite: false,
//     speed: 300,
//     slidesToShow: 3,
//     slidesToScroll: 3,
//     arrows: true,
//     responsive: [
//       {
//         breakpoint: 1100,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 3,
//           infinite: true,
//
//         }
//       },
//       {
//         breakpoint: 850,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 2,
//           infinite: true,
//
//         }
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1
//         }
//       }
//     ]
//   });
//   //$(".slick-next").trigger("click");
// })




function property_slider(){
  $('.property-slider').slickLightbox({
    src: 'src',
    itemSelector: '.light_box_slider',
    caption: 'caption',
    closeOnBackdropClick: false
  });

  // $(document).on('click', '.caption_link_active', function(event){
  //     event.stopPropagation();
  //     //$(this).parent().remove();
  //     console.log("Cd")
  // });

}

$(document).on('change','.change_rent_by_address',function(e){
  e.preventDefault();
  $("#property_address").val($(this).val())
  $("#rent_property_address").val($(this).val())
})
$(document).on("click", ".select_project_to_upgrade",function(e){
  e.preventDefault();
  var property_type_id = $(this).attr("data-property_type_id")
  $.ajax({
    url: "/properties/select_project_to_upgrade",
    type: "get",
    data: {property_type_id: property_type_id},
    headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
    },
    success: function(response){
      if(response.status){
        $(document).find(".show_upgrade_modal_body").html(response.html_data)
        $(document).find("#show_upgrade_modal").modal('show')
        $("#save_property_select_plan_form").validate({
          rules: {
            property_plan_status:{
              required: true
            }
          },
           messages: {
             property_plan_status:{
               required: 'Please select plan'
             }
           }
        })
      }else{
        $('#ajax_flash').show().html("").html('<div class="slide-effect"><div class="alert alert-danger alert-dismissible"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>'+response.messages+' </div></div>');
        settime_to_hide()
      }
    }
  })
})
// property_slider()
$(document).on("click", ".open_simple_property_gallery_slider_modal",function(e){
  $(document).find("#grid_slider").modal('show')
})

$(document).on("click",".open_property_gallery_slider_modal",function(e){
  e.preventDefault()
    property_id = $(this).attr("data-property_id")
    this_tag = $(this)

  $.ajax({
    url:'/get_property_image',
    type:'get',
    data: {property_id: property_id },
    beforeSend: function() {
      $(document).find(".loader-outer").removeClass("d-none")
      $(document).find(".loader-outer").addClass("d-block")
    },
    success:function(response){
      $(document).find(".loader-outer").addClass("d-none")
      $(document).find(".loader-outer").removeClass("d-block")
      if(response.status){

        $(document).find("#grid_slider").modal('show')
        $(document).find(".property_slider_modal_body").html(response.html_data)
        property_slider();
        this_tag.addClass("open_simple_property_gallery_slider_modal")
        this_tag.removeClass("open_property_gallery_slider_modal")
      }else{
        $('#ajax_flash').show().html("").html('<div class="slide-effect"><div class="alert alert-danger alert-dismissible"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>'+response.messages+' </div></div>');
        settime_to_hide()
      }
    }
  })
})

$(document).on('click', '.toggle-password', function() {

    $(this).toggleClass("fa-eye-slash fa-eye");

    var input = $("#pass_log_id");
    input.attr('type') === 'password' ? input.attr('type','text') : input.attr('type','password')
});


$(document).on('click','.current_toggle_password ',function(){

   $(this).toggleClass("fa-eye-slash fa-eye");
     console.log($("#user_current_password"))
   var input = $("#user_current_password");
   input.attr('type') === 'password' ? input.attr('type','text') : input.attr('type','password')
});


$(document).on('click','.current_toggle_password_one ',function(){

   $(this).toggleClass("fa-eye-slash fa-eye");
     // console.log($("#"))
   var input = $("#user_password");
   input.attr('type') === 'password' ? input.attr('type','text') : input.attr('type','password')
});


$(document).on('click','.current_toggle_password_two ',function(){

   $(this).toggleClass("fa-eye-slash fa-eye");
     // console.log($("#"))
   var input = $("#user_password_confirmation");
   input.attr('type') === 'password' ? input.attr('type','text') : input.attr('type','password')
});

$(document).on('click', '.toggle_password_set', function() {

    $(this).toggleClass("fa-eye-slash fa-eye");

    var input = $("#user_password");
    input.attr('type') === 'password' ? input.attr('type','text') : input.attr('type','password')
});

$(document).on('click', '.toggle_password_reset', function() {

    $(this).toggleClass("fa-eye-slash fa-eye");

    var input = $("#user_confirm_password");
    input.attr('type') === 'password' ? input.attr('type','text') : input.attr('type','password')
});

$(document).on('click', '.toggle-register-password', function() {

    $(this).toggleClass("fa-eye-slash fa-eye");

    var input = $("#pass_reg_id");
    input.attr('type') === 'password' ? input.attr('type','text') : input.attr('type','password')
});





$(document).on('turbolinks:load', function() {
  if(window.location.pathname.includes('find_agent') ){
  $(document).scroll(function(e){
    var nearToBottom = 0;
    var round_height = $(window).scrollTop() + $(window).height()
    if (window.pagination_loading) {
      return;
    }


        var url = $('#agent-infinite-scrolling').find('.pagination .next_page').attr('href');
        if(url && round_height  >  $(document).height() - 300) {
        // var last = $('#agent-infinite-scrolling').find('.pagination a').last().prev().attr('href');
        // var agent_name =$(document).find("#agent_name").val()
        // var agent_location =$(document).find("#agent_location").val()
         // console.log(agent_name+" "+agent_location)
        if (url) {
              window.pagination_loading = true;
              $('.loader-outer').removeClass('d-none').addClass('d-block');
                // $('#agent_load_more').text("Please Wait...");
                // $('#agent_load_more').attr("title", "Please Wait...")

                return $.getScript(url).always(function() {
                  $('.loader-outer').addClass('d-none').removeClass('d-block');
                  return window.pagination_loading = false;
                });
        } else {
            // $('#agent_load_more').html("No More Records");
            // $('#agent_load_more').removeAttr("href");
        }
      }
    });
  }

  if(window.location.pathname.includes('upgrade_plan') ){
    if(getUrlParameter('plan_type') == 'prime'){
      $('.plan_select_button_not_clicked[data-plan_id=1]').click()
    }
    if(getUrlParameter('plan_type') == 'exclusive'){
      $('.plan_select_button_not_clicked[data-plan_id=2]').click()
    }
  }
  // custom method to prevent just spaces
   jQuery.validator.addMethod("trimFunc", function(value, element){
         val = value.trim();
         return this.optional(element) || val.length > 0;
   }, "Please enter valid value.");
  // var scroll = $(window).scrollTop();
  // if (scroll >= 50) {
  //     $(".site-header").addClass("inverse fixed-top");
  // } else {
  //     $(".site-header").removeClass("inverse fixed-top");
  // }
  // $(window).scroll(function() {
  //   var scroll = $(window).scrollTop();
  //    if (scroll >= 50) {
  //        $(".site-header").addClass("inverse fixed-top");
  //       }
  //    else {
  //        $(".site-header").removeClass("inverse fixed-top");
  //    }
  // });

  // $(window).scroll(function() {
  //   var scroll = $(window).scrollTop();
  //    if (scroll >= 50) {
  //        $(".filter-form").addClass(" filter-inverse fixed-top");
  //       }
  //    else {
  //        $(".filter-form").removeClass(" filter-inverse fixed-top");
  //    }
  // });

  jQuery.validator.addMethod("initialtrimFunc", function(value, element){
      val = value.replace(/^\s+/g, "");
      return this.optional(element) || val == value;
}, "Please remove extra space.");

  $.validator.addMethod("isValidEmailAddress", function (value, element) {
         var pattern = new RegExp(/^[a-zA-Z0-9_\.%\+\-]+@[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,3}$/);
         return this.optional(element) || pattern.test(value);
   },'Please enter valid email');


  $.validator.addMethod("textOnly", function (value, element) {
           var pattern = /^[a-zA-Z\s]+$/
           return this.optional(element) || pattern.test(value);
     });

  jQuery.validator.addMethod("alphanumeric", function(value, element) {
        return this.optional(element) || /^[\w''.\s-@//',""()]+$/i.test(value);
    }, "Please enter alphanumeric value");


  $.validator.addMethod("notEqual", function(value, element, param) {
    return this.optional(element) || value != param;
  }, "Please specify a different (non-default) value");

  jQuery.validator.addMethod("extension", function (value, element, param) {
                  param = typeof param === "string" ? param.replace(/,/g, '|') : "png|jpe?g|gif";
                  return this.optional(element) || value.match(new RegExp(".(" + param + ")$", "i"));
              }, "Please enter a value with a valid extension.");
    jQuery.validator.addMethod("url2", function(value, element) {

       //just change below regex to your needs /^www\.[a-zA-Z0-9]+\.[a-zA-Z]{1,3}$/
       url = /^www\.[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
       return this.optional(element) || url.test( value );
     }, 'Please enter a valid url address (eg: www.facebook.com).');

  setTimeout(function() {
      $('.alert').hide();
  }, 10000);

  //banner-slider
  $('.banner-slide').slick({
      autoplay: true,
      autoplaySpeed: 3000,
      fade: true,
      arrows: false,
      dots: false
  });

  //property-slider
  $('.prime-slider, .map-prime-slider, .map-exclusive-slider, .exclusive-slider').slick({
      dots: true,
      prevArrow: '<button class="slick-prev prev-arrow icon"></button>',
      nextArrow: '<button class="slick-next next-arrow icon"></button>'
  });


  $('.multiple-select').multipleSelect({
    placeholder: locale_property_details
  })
  $('.add_listing_multiple-select').multipleSelect({
    placeholder: locale_add_listing_property_details
  })
  //list-slider
  $('.list-slider').slick({
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    responsive: [
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,

        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });


  //image-slider
  // $('.slider-for').slick({
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: false,
  //   fade: true,
  //   asNavFor: '.slider-nav'
  // });
  // $('.slider-nav').slick({
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   asNavFor: '.slider-for',
  //   dots: false,
  //   focusOnSelect: true
  // });

  //dealer-slider
  $('.dealer-slider').slick({
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,

        }
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,

        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  //testimonial-slider
  $('.testimonial-slider').slick({
      autoplay: true,
      autoplaySpeed: 5000,
      fade: true,
      arrows: true,
      dots: true
  });


  //dealer-slider
  $('.explore-slider, .sale-slider, .rent-slider, .property-sold').slick({
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,

        }
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,

        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })

  // $('#more_btn').on('click', function () {
  //   $('.checkbox-map-list').toggleClaPlease enter address and cityss('expanded');
  //   $(this).text(function(i, v){
  //      return v === 'More >>' ? 'Less <<' : 'More >>'
  //   })
  // });


window.validate_search = function(){

  $('form.search_property_form').each( function(){
  //  console.log(this)
  $(this).validate({
      rules: {
        property_address:{
          required: true
        }
      },
       messages: {
         property_address:{
           required: validate_user_address_and_city
         }
       }

    })
  })
}

validate_search()

function validate_login(){
  $(document).find('form#sign_in_form').validate({
    rules:{
      'user[email]':{
        required: true,
        minlength: 2,
        maxlength: 100,
        trimFunc: true,
        isValidEmailAddress: true
      },
      'user[password]':{
        required: true,
      }
    },
    messages:{
      'user[email]':{
        required: validate_user_email,
        trimFunc: validate_user_email
      },
      'user[password]':{
         required: validate_user_password,

      }
    }
  })
}

function validate_register(){


  $(document).find('form#new_user').validate({
    rules:{
      ignore: ":hidden",
      'user[name]':{
        required: true,
        trimFunc: true,
        initialtrimFunc:  true
      },
      'user[email]':{
        required: true,
        minlength: 2,
        maxlength: 100,
        trimFunc: true,
        isValidEmailAddress: true,
        remote: {
            url: "/users/check_existing_email",
            data: {type: 'register', exitsting_data: ''},
            type: "post",
            headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            }
        }


      },
      'user[realtorliscenceno]':{
        required : true,
        minlength: 6,
        maxlength: 20,
        alphanumeric: true,
        initialtrimFunc: true,
      },
      'user[phonenumber]':{
        required:true,
        minlength: 10,
        maxlength:10
      },
      'user[password]':{
        required: true,
      },
      'terms_and_conditions':{
        required : true,
      },
      'user[user_type_id]':{
        required: true,
      }

    },
    messages:{
      'user[name]':{
        required: validate_user_name,
        trimFunc: validate_name
      },
      'user[email]':{
        required: validate_user_email,
        remote: already_exist_email,
        trimFunc: validate_email

      },
      'user[realtorliscenceno]':{
        required : validate_user_license_number,
        minlength: license_number_minlength,
        maxlength: license_number_maxlength,
      },
      'user[phonenumber]':{
        required: validate_user_phone_number,
      },
      'user[password]':{
        required: validate_user_password,

      },
      'terms_and_conditions':{
        required: validate_user_terms_conditions
      },
      'user[user_type_id]':{
        required: validate_user_type
      }
    }
  })
}

  //validation for forget password
function validate_forget_password(){
    $('form#forgot_password').validate({
     ignore:[],
      rules: {
        'user[email]':
        {
          required: true,
          minlength: 2,
          maxlength: 100,
          trimFunc: true,
          initialtrimFunc:  true,
          isValidEmailAddress: true,
          remote: {
              url: "/users/check_existing_email",
              data: {type: 'forgot', exitsting_data: ''},
              type: "post",
              headers: {
              'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
              }
          }},
          'user[password]':{
            required: true,
          }},
      messages:{
      'user[email]':{
        required: validate_user_email,
        trimFunc: validate_email,
        isValidEmailAddress: validate_email,
        remote: validate_existing_email
      },
      'user[password]':{
          required: validate_user_password}
     }
    })
}

// validating change password form(old)
  $('form#forgot_change_password').validate({
    rules:{
      'user[password]':{
        required: true,
        minlength: 6,
        maxlength: 20,

      },
      'user[password_confirmation]':{
        required: true,
        equalTo: '#user_password'
      }
    },
    messages:{
      'user[password]':{
        required: validate_new_user_password,
        minlength: password_minlength,
        maxlength: password_maxlength
      },
      'user[password_confirmation]':{
        required: confirm_password_required,
        equalTo:  password_does_not_match
      }
    }
  })

  //validating current_change_password form(new)
  $('form#change_password').validate({
    rules:{
      'user[current_password]':{
        required: true,
        minlength: 6,
        maxlength: 20,
        remote: {
            url: "/users/check_current_password",
            type: "post",
            beforeSend: function() {
                showloader = false;
            }
            }
      },
      'user[password]':{
        required: true,
        minlength: 6,
        maxlength: 20,
        notEqual: function(){return $('#user_current_password').val()},
      },
      'user[password_confirmation]':{
        required: true,
        equalTo: '#user_password'
      }
    },
    messages:{
      'user[current_password]':{
        required: validate_current_user_password,
        minlength: password_minlength,
        maxlength: password_maxlength,
        remote: validate_current_password,
      },
      'user[password]':{
        required: validate_new_user_password,
        minlength: password_minlength,
        maxlength: password_maxlength,
        notEqual: validate_new_user_password
      },
      'user[password_confirmation]':{
        required: validate_confirm_user_password,
        equalTo: confirm_new_password_not_match
      }
    }
  })

  // validation for edit profile

  $('form#edit_user').validate({
    rules:{
      'user[name]':{
        required: true,
        initialtrimFunc:  true,
        minlength: 2,
        maxlength: 50,
        textOnly: true,
      },

      'user[phonenumber]':{
        required: true,
        minlength: 10,
        maxlength: 10,
        initialtrimFunc:  true,
      },

      'user[email]':{
        required: true,
        minlength: 2,
        maxlength: 50,
        initialtrimFunc:  true,

      },

      'user[bio]':{

        minlength: 5,
        maxlength: 500,
        initialtrimFunc:  true,
      },

      'user[realtorliscenceno]':{
        required : true,
        minlength: 6,
        maxlength: 20,
        alphanumeric: true,
        initialtrimFunc: true,
      },

      'user[specialist]':{
        alphanumeric: true,
        initialtrimFunc:  true,
      },

      'user[areacoverage]':{


      },

      'user[website]':{
        minlength: 2,
        maxlength: 200,
        url2: true,
        initialtrimFunc:  true,
      },

      'user[facebook]':{
        minlength: 2,
        maxlength: 200,
        url: true,
        initialtrimFunc:  true,

      },

      'user[instagram]':{
        minlength: 2,
        maxlength: 200,
        url: true,
        initialtrimFunc:  true,

      },

      'user[linkedin]':{
        minlength: 2,
        maxlength: 200,
        url: true,
        initialtrimFunc:  true,

      },

      'user[youtube]':{
        minlength: 2,
        maxlength: 200,
        url: true,
        initialtrimFunc:  true,

      },
      'user[profile_image]':{
        extension: 'jpg|png|jpeg'
      }

    },
    messages:{
      'user[name]':{
        required: validate_lead_full_name,
        textOnly: validate_text_only,
        minlength: validate_name_minlength,
        maxlength: validate_name_maxlength,
      },

      'user[phonenumber]':{
        required: validate_lead_phone_number,
        minlength: phone_minlength,
        maxlength: phone_maxlength,
      },

      'user[email]':{
        required: validate_user_email,
        minlength: email_minlength,
        maxlength: validate_email_maxlength,

      },

      'user[bio]':{
        minlength: validate_bio_minlength,
        maxlength: validate_bio_maxlength,

      },

      'user[realtorliscenceno]':{
        required : validate_user_license_number,
        minlength: license_number_minlength,
        maxlength: license_number_maxlength,
      },

      'user[specialist]':{
        alphanumeric: validate_text_only,
        textOnly: validate_text_only
      },

      'user[areacoverage]':{

      },

      'user[website]':{
        url2: validate_website_link,
      },

      'user[facebook]':{
         url: validate_facebook_link,
      },

      'user[instagram]':{
         url: validate_insta_link,
      },

      'user[linkedin]':{
        url: validate_linkedin_link,
      },

      'user[youtube]':{
       url: validate_youtube_link,
      },
      'user[profile_image]':{
        extension: validate_image_file
      }


    }
  })


  //validation for company details


  $('form#edit_company').validate({
    rules:{
      'user_company[company_name]':{
        required: true,
        trimFunc: true,
        initialtrimFunc:  true,

      },

      'user_company[company_number]':{
        required: true,
        minlength: 10,
        trimFunc: true,
        maxlength: 10,
        initialtrimFunc:  true,


      },

      'user_company[company_email]':{
        required: true,
        minlength: 2,
        trimFunc: true,
        maxlength: 50,
        isValidEmailAddress: true,
        initialtrimFunc:  true,
      },

      'user_company[company_address]':{
        required: true,
        trimFunc: true,
        initialtrimFunc:  true,
      },


      'user_company[company_website]':{
           minlength: 2,
          maxlength: 200,
          trimFunc: true,
          url2: true,
          initialtrimFunc:  true,
      },

      'user_company[company_facebook]':{
           minlength: 2,
           maxlength: 200,
           url: true,
           trimFunc: true,
           initialtrimFunc:  true,


      },

      'user_company[company_instagram]':{
           minlength: 2,
           maxlength: 200,
           url: true,
           trimFunc: true,
           initialtrimFunc:  true,

      },

      'user_company[company_linkedin]':{
           minlength: 2,
          maxlength: 200,
           url: true,
           trimFunc: true
      },

      'user_company[company_youtube]':{
          minlength: 2,
          maxlength: 200,
           url: true,
           trimFunc: true,
           initialtrimFunc:  true,

      },

      'user_company[company_logo]':{
        extension: 'jpg|png|jpeg',
      }

    },
    messages:{
      'user_company[company_name]':{
        required: validate_lead_full_name,
        trimFunc: validate_name
      },

      'user_company[company_number]':{
        required: validate_lead_contact_number,
        minlength: validate_contact_minlength,
        maxlength: validate_contact_minlength,
        trimFunc: validate_contact
      },

      'user_company[company_email]':{
        required: validate_user_email,
        minlength: email_minlength,
        maxlength: validate_email_maxlength,
        trimFunc: validate_email,
        isValidEmailAddress: validate_email
      },

      'user_company[company_address]':{
        required: validate_user_company_address,
        trimFunc: validate_user_company_address
      },

      'user_company[company_website]':{
        url2: validate_website_link,
        trimFunc: validate_website_link
      },

      'user_company[company_facebook]':{
        url: validate_facebook_link,
        trimFunc: validate_facebook_link
      },
      'user_company[company_instagram]':{
         url: validate_insta_link,
         trimFunc: validate_insta_link
      },

      'user_company[company_linkedin]':{
         url: validate_linkedin_link,
         trimFunc: validate_linkedin_link

      },

      'user_company[company_youtube]':{
          url: validate_youtube_link,
          trimFunc: validate_youtube_link
      },
      'user_company[company_logo]':{
        extension: validate_image_file,
      }


    }
  });
function validate_lead(){
  $('form#save_lead_contact_form').validate({
    rules:{
      'lead[full_name]':{
        required: true,
        initialtrimFunc:  true,
        minlength: 2,
        maxlength: 50,
        textOnly: true,
      },

      'lead[phone_number]':{
        required: true,
        minlength: 10,
        maxlength: 10,
        initialtrimFunc:  true,
      },

      'lead[email]':{
        required: true,
        minlength: 2,
        maxlength: 50,
        initialtrimFunc:  true,
      },

      'lead[messages]':{
        minlength: 5,
        maxlength: 1000,
        initialtrimFunc:  true,
      },

    },
    messages:{
      'lead[full_name]':{
        required:  validate_lead_full_name,
         textOnly: validate_text_only,
        minlength: validate_name_minlength,
        maxlength: validate_name_maxlength,
      },

      'lead[phone_number]':{
        required: validate_lead_phone_number,
        minlength: phone_minlength,
        maxlength: phone_minlength,
      },

      'lead[email]':{
        required: validate_lead_email,
        minlength: email_minlength,
        maxlength: validate_email_maxlength,
      },

      'lead[messages]':{
        minlength: validate_messages_minlength,
        maxlength: validate_messages_maxlength,
      }
    }
  })
}
function save_agent_contact(){
    $('form#save_agent_contact_form').validate({
      rules:{
        'agent[full_name]':{
          required: true,
          initialtrimFunc:  true,
          minlength: 2,
          maxlength: 50,
          textOnly: true,
        },

        'agent[phone_number]':{
          required: true,
          minlength: 10,
          maxlength: 10,
          initialtrimFunc:  true,
        },

        'agent[email]':{
          required: true,
          minlength: 2,
          maxlength: 50,
          initialtrimFunc:  true,
        },

        'agent[messages]':{
          minlength: 5,
          maxlength: 1000,
          initialtrimFunc:  true,
        },

      },
      messages:{
        'agent[full_name]':{
          required:  validate_lead_full_name,
           textOnly: validate_text_only,
          minlength: validate_name_minlength,
          maxlength: validate_name_maxlength,
        },

        'agent[phone_number]':{
          required: validate_lead_phone_number,
          minlength: phone_minlength,
          maxlength: phone_minlength,
        },

        'agent[email]':{
          required: validate_lead_email,
          minlength: email_minlength,
          maxlength: validate_email_maxlength,
        },

        'agent[messages]':{
          minlength: validate_messages_minlength,
          maxlength: validate_messages_maxlength,
        }
      }
    })
}
save_agent_contact()
validate_lead()
global.validate_announcement = function (){

$('form#announcement').validate({
  rules:{
    'property_announcement[schedule_date]':{
      required: true,

    },

    'property_announcement[start_time]':{
      required: true,

    },

    'property_announcement[end_time]':{
      required: true,

    },


  },
  messages:{
    'property_announcement[schedule_date]':{
      required:  validate_shedule_date,
    },

    'property_announcement[start_time]':{
      required: validate_start_time,

    },

    'property_announcement[end_time]':{
      required: validate_end_date,

    },

  }
})
}
$(document).on('click','.click_open_house_annoucement_rsvp',function(e){
  e.preventDefault();
  // console.log('checking rsvp')
  property_id = $(this).attr('data-property_id')
  $.ajax({
    url: '/open_rsvp_modal',
    type: "post",
    data: {property_id: property_id},
    success: function(response){
      if(response['status']){
        $("#open_house_annoucement_rsvp_body").html(response['html_data'])
        $('#open_house_annoucement_rsvp').modal('show')
        validate_lead()
      }
    }
  })
})
function recaptch_form(){
  //alert("")
  var recaptcha_form=$(document).find(".my_captcha_form")[0]
      var version = $('.check_version').data('version')
      if(typeof recaptcha_form != 'undefined' && version == 'v2') {


          $('.my_captcha_form').on("submit", function (evt) {
              var response = grecaptcha.getResponse();
              // console.log(response.length == 0)
              if (response.length == 0) {
                  //reCaptcha not verified
                  //console.log($(document).find('form#new_user').valid())

                  $(document).find('#recaptcha_message-error').html(validate_captcha);
                  $(document).find('#recaptcha_message-error').show();
                  console.log($('#recaptcha_message-error'))
                  evt.preventDefault();
                  return false;
              }else{
                  $(document).find('#recaptcha_message-error').html("");
                  $(document).find('#recaptcha_message-error').hide();
              }
              //captcha verified
              //do the rest of your validations here

          });
      }
    }
  // $(document).on('submit',"form#sign_in_form",function(){
  //   $(document).find('form#sign_in_form').validate()
  // })
  // toogle field password login input





   // register , login , forget  modal ajax

  $(document).on("click",".register_popup",function(e){
    e.preventDefault();
    // alert("vfd")
    $.ajax({
      type:'GET',
      url:'/users/sign_up',
      success:function(response){
        //I assume you want to do something on controller action execution success?
        if(response["status"]){
          $(document).find(".add_login_form").html(response["html_data"])



          $(document).find("#signInModal").modal('hide')
          setTimeout(function() {
            $(document).find("#registerModal").modal('show')
          }, 600);

          // console.log($("body"))
          // if ($('.modal:visible').length) {
          //   $('body').addClass('modal-open');
          // }
            validate_register()
            recaptch_form()

          // check_input_function()
        }
      }
    });
  });




  $(document).on("click",".signin_popup",function(e){

    e.preventDefault();
    // alert("vfd")
    $.ajax({
      type:'GET',
      url:'/users/new_signin',
      success:function(response){
        //I assume you want to do something on controller action execution success?
        if(response["status"]){
          $(document).find(".add_signin_form").html(response["html_data"])
          $(document).find("#registerModal").modal('hide')

          setTimeout(function() {
            $(document).find("#signInModal").modal('show')
          }, 600);


           validate_login()
           $(document).find(".loader-outer").removeClass("d-block")
           $(document).find(".loader-outer").addClass("d-none")

        }
      }
    });
  });

  $(document).on("click",".forget_popup",function(e){
    e.preventDefault();
    $.ajax({
      type:'GET',
      url: '/users/new_password',
      success:function(response){
        if (response["status"]){
          $(document).find(".add_forget_password_form").html(response["html_data"])
          $(document).find("#forgetModal").modal('show')
          $(document).find("#signInModal").modal('hide')
          validate_forget_password()

        }
      }
    });
  });

  //jquery for user type value
function check_input_function(){
  $(document).on("change",".check_input",function(e){
    if($(this).val() == '1'){
      $(document).find('.user_realtorliscenceno_div').show()
      $("#user_realtorliscenceno").show()
    }else{
      $(document).find('.user_realtorliscenceno_div').hide()
      $("#user_realtorliscenceno").hide()
      $("#user_realtorliscenceno").val('')
    }
    if($(this).val() == '1' || $(this).val() == '3' ){
      $(document).find('.user_phonenumber_div').show()
      $("#user_phonenumber").show()
    }else{
      $(document).find('.user_phonenumber_div').hide()
      $("#user_phonenumber").hide()
      $("#user_phonenumber").val('')
    }
    // if($(this).prop("clicked")== true){
    type_val =$(this).val()
    $(document).find("#user_user_type_id").val(type_val)
    facebook_href =  $(document).find('.facebook_signup').attr("href")
    new_face_book_href =  facebook_href.replace(/type.*$/i, "type="+type_val)
    google_href =  $(document).find('.google_signup').attr("href")
    new_google_href =  google_href.replace(/type.*$/i, "type="+type_val)
    $(document).find('.facebook_signup').attr("href",new_face_book_href)
    $(document).find('.google_signup').attr("href",new_google_href)
    // }
  });
}
check_input_function()

  if(getUrlParameter('signin') == 'true'){

    if($(document).find(".signin_popup").length > 0 ){
      $(document).find(".signin_popup")[0].click()
    }

  }

  if(getUrlParameter('signup') == 'true'){
    // console.log('fes')
    if($(document).find(".register_popup").length > 0 ){
      $(document).find(".register_popup")[0].click()
    }


  }
  if( window.location.pathname.includes('properties/view_property_detail')){

    param_property_id = $(document).find("#lead_property_id").val()
    //console.log(param_property_id)
    //var storedNames = JSON.parse(localStorage.getItem("property_id"));

    var check_store_name = JSON.parse(localStorage.getItem("property_id"))
    //  console.log(check_store_name)
    if(check_store_name == null){
      check_store_name = [];
    }

  //  console.log(jQuery.inArray(param_property_id, check_store_name))
    //console.log(check_store_name)
    if(jQuery.inArray(param_property_id, check_store_name) == -1){
      $.ajax({
        url: '/save_property_detail_view',
        type: 'get',
        data: {property_id: param_property_id},
        success: function(response){
          if(response.status){
            //console.log('add view')
            check_store_name.push(param_property_id)
            localStorage.setItem("property_id", JSON.stringify(check_store_name));
            //var storedNames = JSON.parse(localStorage.getItem("property_id"));
            //console.log(storedNames)
          }else{
            console.log('error')
          }
        }

      })
    }



  }


  if(getUrlParameter('company_details') == 'true'){

    //console.log($(document).find("#signin_popup")[0])
    if($(document).find("#nav-company-tab").length > 0 && $(document).find("#nav-tab").length > 0 ){
      $(document).find("#nav-company-tab").click()
      // $(document).find("#nav-company-tab").attr("aria-selected",true)
      // $(document).find("#nav-tab").attr("aria-selected",false)
      // $(document).find("#nav-tab").removeClass('active')
      // $(document).find("#nav-company-tab").addClass('active')
      // $(document).find("#nav-company").addClass('active show')
      // $(document).find("#nav-personal").removeClass('active show')
    }

  }
  // if(getUrlParameter('company_details') == 'true'){
  //   if($(document).find("#nav-company-tab").length > 0 && $(document).find("#nav-tab").length > 0 ){
  //     $(document).find("#nav-company-tab").click()
  // }

//console.log(getUrlParameter('forget'))

  if(getUrlParameter('forget') == 'true'){

    // console.log($(document).find("#signin_popup"))
    // //if($(document).find("#signin_popup")[0]){
    //   $(document).find("#signin_popup")[0].click()
    // //}

    //console.log($(document).find("#signin_popup")[0])
    if($(document).find(".forget_popup").length > 0 ){
      $(document).find(".forget_popup")[0].click()
    }


  }



function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return false;
};

});
