import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["map","lat", "lng","string_corrd"]

  connect() {

    //console.log(this.getLocation())
    if (typeof (google) != "undefined"){
      this.initMap()

    }
  }

initMap(){
  // this.mapTarget.forEach((element, index) => {
    var lat = this.latTarget.value
    var lng = this.lngTarget.value
    var array_coord = this.string_corrdTarget.value

  var map  = new google.maps.Map(this.mapTarget, {
    center: new google.maps.LatLng(
      parseFloat(lat),
      parseFloat(lng)
    ),
    zoom: 10,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: true,
    zoomControlOptions: {
        style: google.maps.ZoomControlStyle[true],
        //position: google.maps.ControlPosition[null]
    },
    disableDefaultUI: false,
    draggable: true,
    scrollwheel: true,
    disableDoubleClickZoom: false,
    panControl: true,
    streetViewControl: false,
    overviewMapControl: true,
    mapTypeControl: false,
    scaleControl: true,
    rotateControl: true,
    fullscreenControl: false
  })

  var marker = new google.maps.Marker({
    map: map,
    anchorPoint: new google.maps.Point(0,0),
    draggable:true,
    animation: google.maps.Animation.DROP
  })
  let mapLocation = {
    lat: parseFloat(lat),
    lng: parseFloat(lng)
  }
  marker.setPosition(mapLocation)
  marker.setVisible(true)

  console.log(array_coord)
  if(array_coord != ''){
    var parse_array_coord = JSON.parse(array_coord)
  var saved_poly = new google.maps.Polygon({
    paths: parse_array_coord,
    clickable: false,
    fillColor: '#BCDCF9',
    fillOpacity: 0.5,
    strokeWeight: 3,
    strokeColor: '#57ACF9',
    zIndex: 1
  });
  //saved_poly.setPath(array_data)
  saved_poly.setMap(map);
}
}

}
