import consumer from "./consumer"
const update_user_list= ()=> {
    consumer.subscriptions.create({
        channel: 'UserListChannel'
    }, {
        connected: function () {
          //  console.log("User list socket connected");
        },
        disconnected: function () {
          //  console.log("User list socket disconnected");
        },
        received: function (response) {
          //  console.log("User list socket data received");
            var present_user_id = $('#present_user_id').val();
          //  console.log(response['data'])
            if (response['data']['user_ids'].includes(parseInt($(".current_user_id").html()))) {
               $.ajax({
                  url: '/get_updated_user_list',
                  type: 'GET',
                  data: {"user_id": $(".current_user_id").html(), "present_user_id": present_user_id},
                  success: function(response){
                    // console.log(response);
                     if(response['status']){
                       $('.parent_user_list').html(response['data']);
                     }
                     if(response['unread_messages']) {
                       $('.total_unread_messages').html(response['unread_messages']);
                     }
                  }
                });
                /*if (response['data'][$('.current_login_user').val()]) {
                    resent_user=response['data'][$('.current_user').val()]
                } else {
                    resent_user=response['data']['users_list']
                }
                $('.default_user_list').append(resent_user);*/
            } else {
                console.log("error occurred after data received om user list channel.")
            }
        }
    })
}
export { update_user_list };
