// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["field", "map", "latitude", "longitude", "zipcode", "city"]

  connect() {

    //console.log(this.getLocation())
    if (typeof (google) != "undefined"){
      this.initMap()

    }
  }
  initMap(){
    this._infowindow = new google.maps.InfoWindow();
    this.map()
    this.marker()
    this.autocomplete()
    this.eventListner()
    // console.log('init')
  }


  map() {
    if(this._map == undefined) {
      this._map = new google.maps.Map(this.mapTarget, {
        center: new google.maps.LatLng(
          parseFloat(this.latitudeTarget.value),
          parseFloat(this.longitudeTarget.value)
        ),
        zoom: 13,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        zoomControl: true,
        zoomControlOptions: {
            style: google.maps.ZoomControlStyle[true],
            //position: google.maps.ControlPosition[null]
        },
        disableDefaultUI: false,
        draggable: true,
        scrollwheel: true,
        disableDoubleClickZoom: false,
        panControl: true,
        streetViewControl: true,
        overviewMapControl: true,
        mapTypeControl: false,
        scaleControl: true,
        rotateControl: true,
        fullscreenControl: false
      })
    }
    google.maps.event.addListener(this._map, "click", function(e){
      this._infowindow.close()
    }.bind(this));
    return this._map
  }

  infowindow(){

      this._infowindow =new google.maps.InfoWindow({
        content: this.fieldTarget.value || ""
      });

    return this._infowindow
  }

  marker() {
    if (this._marker == undefined) {
      this._marker = new google.maps.Marker({
        map: this.map(),
        anchorPoint: new google.maps.Point(0,0),
        draggable:true,
        animation: google.maps.Animation.DROP
      })
      let mapLocation = {
        lat: parseFloat(this.latitudeTarget.value),
        lng: parseFloat(this.longitudeTarget.value)
      }
      this._marker.setPosition(mapLocation)
      this._marker.setVisible(true)


    google.maps.event.addListener(this._marker, 'dragend', this.eventListner.bind(this));
    }

    google.maps.event.addListener(this._marker, "click", this.open_infowindow.bind(this,this._infowindow,this._marker));
    return this._marker
  }

open_infowindow(infowindow,marker){

    infowindow.setContent(this.fieldTarget.value);
    infowindow.open(this.map(),marker);

}
eventListner(){
  this.geocodePosition(this.marker().getPosition());
}


geocodePosition(pos)
{
   var geocoder = new google.maps.Geocoder();

   geocoder.geocode
    ({
        latLng: pos
    },
        (results, status)=>
        {

            if (status == google.maps.GeocoderStatus.OK)
            {


              this.latitudeTarget.value = results[0].geometry.location.lat()
              this.longitudeTarget.value = results[0].geometry.location.lng()

              //  $("#mapErrorMsg").hide(100);
              let address1 = "";
              let postcode = "";
              let address2 = "";
              for (const component of results[0].address_components) {
              const componentType = component.types[0];

              switch (componentType) {
                case "street_number": {
                   address2 = `${component.long_name} ${address1}`;
                  break;
                }

                case "route": {
                   address2 += component.short_name;
                  break;
                }

                case "postal_code": {
                  postcode = component.long_name;
                  break;
                }

                case "postal_code_suffix": {
                  // postcode = `${postcode}-${component.long_name}`;
                  break;
                }
                case "locality":
                  address1 = component.long_name;
                  break;

                case "administrative_area_level_1": {
                //  document.querySelector("#state").value = component.short_name;
                  break;
                }
                case "country":
                //  document.querySelector("#country").value = component.long_name;
                  break;
              }
            }
            //console.log(postcode)

            this.fieldTarget.value= results[0].formatted_address
            this.cityTarget.value = address1
            this.zipcodeTarget.value = postcode
            this._infowindow.close()

            }
            else
            {
                //$("#mapErrorMsg").html('Cannot determine address at this location.'+status).show(100);
            }
        }
    );
}

  autocomplete() {

    if (this._autocomplete == undefined) {
      this._autocomplete = new google.maps.places.Autocomplete(this.fieldTarget)
      this._autocomplete.bindTo('bounds', this.map())
      this._autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
      this._autocomplete.addListener('place_changed', this.locationChanged.bind(this))
    }
    return this._autocomplete
  }

  locationChanged() {
    let place = this.autocomplete().getPlace()

    if (!place.geometry) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      window.alert("No details available for input: '" + place.name + "'");
      return;
    }
    let address1 = "";
    let postcode = "";
    let address2 = "";
    console.log(place.geometry.location.lat())
    console.log(place.geometry.location.lng())
    this.map().fitBounds(place.geometry.viewport)
    this.map().setCenter(place.geometry.location)
    this.marker().setPosition(place.geometry.location)
    this.marker().setVisible(true)
    for (const component of place.address_components) {
    const componentType = component.types[0];

    switch (componentType) {
      case "street_number": {
         address2 = `${component.long_name} ${address1}`;
        break;
      }

      case "route": {
         address2 += component.short_name;
        break;
      }

      case "postal_code": {
        postcode = component.long_name;
        break;
      }

      case "postal_code_suffix": {
        // postcode = `${postcode}-${component.long_name}`;
        break;
      }
      case "locality":
        address1 = component.long_name;
        break;

      case "administrative_area_level_1": {
      //  document.querySelector("#state").value = component.short_name;
        break;
      }
      case "country":
      //  document.querySelector("#country").value = component.long_name;
        break;
    }
  }
    this.latitudeTarget.value = place.geometry.location.lat()
    this.longitudeTarget.value = place.geometry.location.lng()

    this.cityTarget.value = address1
    this.zipcodeTarget.value = postcode
  }
  //
  getLocation() {

  if (navigator.geolocation) {

    navigator.geolocation.getCurrentPosition(this.successFunction.bind(this),this.errorFunction.bind(this))
  } else {

    var lat= Default_Lat
    var lan  =Default_Long
    this._lat_lan = new google.maps.LatLng(lat,lng)

    return this.lat_lan
  }
}

successFunction(position) {
  console.log(position)
    if (position != undefined) {
    var lat = position.coords.latitude;
    var lng = position.coords.longitude;
    this._lat_lan =  new google.maps.LatLng(this._lat,this._long)}
    else{
      var lat = Default_Lat;
      var lng = Default_Long;
      this._lat_lan =  new google.maps.LatLng(this._lat,this._long)
      return  this._lat_lan
    }
    return  this._lat_lan
}
errorFunction(){
  var lat = Default_Lat;
  var lng = Default_Long;
  this._lat_lan =  new google.maps.LatLng(this._lat,this._long)
  return  this._lat_lan
}

  preventSubmit(e) {
    if (e.key == "Enter") { e.preventDefault() }
  }





}
