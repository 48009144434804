import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'map' ]

  connect() {
    this._school_array = []
    this._restaurant_array = []
    this._hospital_array = []
    this._department_store_array = []
    this._pharmacy_array = []
    this._shopping_mall_array = []
    this._school= false
    this._restaurant= false
    this._hospital= false
    this._department_store = false
    this._pharmacy= false
    this._shopping_mall= false

    this._bounds = new google.maps.LatLngBounds();

    var markers = []

    if (typeof (google) != "undefined"){

      this.markedMap()
    }



  }

  markedMap(){
    if(this.hasMapTarget) {

    let mapLocation = {
      lat: parseFloat(this.mapTarget.dataset.lat),
      lng: parseFloat(this.mapTarget.dataset.lon)
    }
    this._infowindow = new google.maps.InfoWindow({maxWidth: 300,
    maxHeight: 100,pixelOffset: new google.maps.Size(170, 120)});
    this.map(mapLocation)
    this.styleMap()
    //var bounds = new google.maps.LatLngBounds();
    this.marker(mapLocation)
    google.maps.event.addListener(this._infowindow, 'domready', function () {
      var rating = $("#rating_of_address").attr("data-score");
      $('#rating_of_address').raty({ scoreName:  rating, half  : false,size:       24, starHalf: '/assets/star-half.png', starOff: '/assets/star-off.png', starOn:  '/assets/star-on.png', readOnly : true });
      //console.log($(document).find('.marker_details_slider'))
      $(document).find('.marker_details_slider').not('.slick-initialized').slick({
          dots: true,
          prevArrow: '<button class="slick-prev prev-arrow icon"></button>',
          nextArrow: '<button class="slick-next next-arrow icon"></button>'
      });
});

google.maps.event.addListener(this.map(), 'click', function(){
  this._infowindow.close()
}.bind(this));

    //this.map().fitBounds(bounds)
  }
  }

  styleMap(){
    var styles = [
   {
     featureType: "poi",
     stylers: [
      { visibility: "off" }
     ]
    }
];

this._styledMap = new google.maps.StyledMapType(styles,{name: "Styled Map"});
this._map.mapTypes.set("styled_map", this._styledMap);
this._map.setMapTypeId("styled_map");
  }

  map(mapLocation){

    if(this._map == undefined) {

    this._map = new (google.maps.Map)(this.mapTarget, { zoom: 13, center: mapLocation,fullscreenControl: false , mapTypeControl:false});
   }
   return this._map
  }

  marker(mapLocation){

    //var infowindow = new google.maps.InfoWindow();

    if (this._marker == undefined) {
      this._marker = new google.maps.Marker({
        map: this.map(),
        anchorPoint: new google.maps.Point(0,0),
        icon: this.mapTarget.dataset.iconurl,
        animation: google.maps.Animation.DROP,
        zoom: 8
      })

      this._marker.setPosition(mapLocation)

      this._marker.setVisible(true)

    }

    return this._marker
  }

  createMarker(place, value,icon) {
    var icon_image = {
                    url: icon,
                    scaledSize : new  google .maps.Size(20, 29),
                };
   var placeLoc = place.geometry.location;
   var marker = new google.maps.Marker({
     map: this.map(),
     anchorPoint: new google.maps.Point(0,0),
     zoom: 17,
     icon:icon_image

   });

     marker.setPosition(placeLoc)

     marker.setVisible(true)
     this._bounds.extend(placeLoc);
     if(value== 'school'){
       this._school_array.push(marker)
       this._school = true
     }else if (value=='restaurant') {
       this._restaurant_array.push(marker)
       this._restaurant=true
     }else if (value=='hospital') {
       this._hospital_array.push(marker)
       this._hospital = true
     }else if (value=='convenience_store') {
       this._department_store_array.push(marker)
       this._department_store = true
     }else if (value=='pharmacy') {
       this._pharmacy_array.push(marker)
       this._pharmacy = true
     }else if (value=='store') {
       this._shopping_mall_array.push(marker)
       this._shopping_mall = true
     }else{

     }
     //bounds.extend(placeLoc);


     return marker

 }

  search_near_by_place(_event){



    var element = _event.target
    // console.log(element)
    var value = $(element).val()

    var check_status = $(element).is(':checked')
    var icon = $(element).attr('data-icon_url')
    var latlon = new google.maps.LatLng(parseFloat(this.mapTarget.dataset.lat), parseFloat(this.mapTarget.dataset.lon));
    var name = value
    if(value == 'store'){
    var   name = 'shopping_mall'
    }

    var request = {
       location: latlon,
       radius: 5000,
       type: name
     };

     //console.log(check_status)
     if(check_status ){
       //this._bounds = new google.maps.LatLngBounds();
        this._service = new google.maps.places.PlacesService(this.map());
        this._service.nearbySearch(request,(results, status)=>{
          //console.log(request)
          if (status == google.maps.places.PlacesServiceStatus.OK) {
          //  console.log(value)

            for (var i = 0; i < results.length; i++) {
              var marker =this.createMarker(results[i],value,icon);

                // var new_map_loction = results[i].geometry.location
                // bounds.extend()

                google.maps.event.addListener(marker, 'click', this.infowindow.bind(this,results[i], marker));
            }
             this.map().fitBounds(this._bounds)
            if(value== 'school'){

              if(this._school){
                this.show_marker(this._school_array)
              }
            }else if (value=='restaurant') {
              if(this._restaurant){
                this.show_marker(this._restaurant_array)
              }

            }else if (value=='hospital') {
              if(this._hospital ){
                this.show_marker(this._hospital_array)
              }
            }else if (value=='convenience_store') {
              if(this._department_store ){
                this.show_marker(this._department_store_array)
              }
            }else if (value=='pharmacy') {
              if(this._pharmac){
                this.show_marker(this._pharmacy_array)
              }
            }else if (value=='store') {
              if(this._shopping_mall_array.length > 0 ){
                this.show_marker(this._shopping_mall_array)
              }
            }else{

            }

          }
          else{
            console.log("Status not OK")
          }
        })
    }else{
      if(value=='school'){
      //  console.log('school')
        this.delete_marker(this._school_array)

      }else if(value=='restaurant'){
        //console.log(this._restaurant_array)
        this.delete_marker(this._restaurant_array)

      }else if (value=='hospital') {
        //console.log('hospital')
        this.delete_marker(this._hospital_array)

      }else if(value=='convenience_store') {
        //console.log('department_store')
        this.delete_marker(this._department_store_array)

      }else if (value=='pharmacy') {
        //console.log('phat')
        this.delete_marker(this._pharmacy_array)

      }else if(value=='store'){
        this.delete_marker(this._shopping_mall_array)

      }else{

      }

    }
  }

  infowindow(place,marker){
    //console.log(marker)
    // this._infowindow.setContent(`<iframe width="600" height="450" frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAnepTqZdNMjQo4BnGRQhWMIUvvb69hEC8&q=' + ${marker.position.lng()} + ',' + ${marker.position.lat()} + '}" allowfullscreen> </iframe>`);
    // this._infowindow.open(this.map(),marker);
    //console.log(place)
    let request = {
             placeId: place.place_id,
             fields: ['name', 'formatted_address', 'geometry', 'rating',
               'website', 'photos','opening_hours','type', 'formatted_phone_number','reviews']
           };

           /* Only fetch the details of a place when the user clicks on a marker.
            * If we fetch the details for all place results as soon as we get
            * the search response, we will hit API rate limits. */
           this._service.getDetails(request, (placeResult, status) => {
             this.showDetails(placeResult, marker, status)
           });
    // let rating = "None";
    //  if (place.rating) rating = place.rating;
    //  this._infowindow.setContent('<div><strong>' + place.name +
    //    '</strong><br>' + 'Rating: ' + rating + '</div>');

  }

  // Step 4C: Show place details in an info window */
    // Builds an InfoWindow to display details above the marker
   showDetails(placeResult, marker, status) {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        //let placeInfowindow = new google.maps.InfoWindow();
         //console.log(placeResult)
        let rating = "";
        let loopphotos = ''
        let address = ''
        let phone = ''
        let reviews = ''
        let is_open = ''
        let open_div = ''
        let phone_div = ''
        let today_date = new Date()
        if (placeResult.rating) rating = placeResult.rating;
        if(placeResult.photos){
          $.each(placeResult.photos,function(i){
            // loopphotos = loopphotos+' <div class="place_map_image col-sm-12" ><img src="'+placeResult.photos[i].getUrl()+'" height="50" width="100"></div> '
            loopphotos = loopphotos+' <div class="place_map_image" ><img src="'+placeResult.photos[i].getUrl()+'" height="50" width="100"></div> '
          })
        }
        if(placeResult.opening_hours){
          is_open = placeResult.opening_hours.isOpen()
          // console.log(is_open)
          // console.log(today_date.getDay())

            $.each(placeResult.opening_hours.weekday_text,function(i){
              if(i == today_date.getDay()){
                var time = placeResult.opening_hours.weekday_text[i].split(/:(.+)/);
                open_div = `<i class="fas fa-clock  mr-2"></i><div>${time[1]}</div>`;

              }

            })

          //}
          // $.each(placeResult.opening_hours.weekday_text,function(i){
          //   loopphotos = loopphotos+' <div class="opening_hours_of_place col-sm-12" ></div> '
          // })
        }
        if(placeResult.formatted_address) address = placeResult.formatted_address
        if(placeResult.formatted_phone_number) phone = placeResult.formatted_phone_number
        if(phone){
          phone_div = `<i class="fas fa-phone-alt  mr-2"></i>${phone}`
        }
        if(placeResult.reviews && placeResult.reviews.length > 0) reviews = placeResult.reviews.length

    let content = `<div  height='100' width='100'><div height='40' width='100' class="marker_details_slider"> ${loopphotos}</div><div class="info-body"><strong class="p-med">${placeResult.name} </strong><div class="rating-sec"><span id="rating_of_address" data-score="${rating}"></span><strong class="p-med ml-2">${rating}</strong></div><div class="d-flex mb-1"><i class="fas fa-address-card mr-2 align-middle mt-1"></i>${address}</div><div class="d-flex align-items-center mb-1"> ${phone_div}</div><div class="d-flex align-items-center mb-1">${open_div}</div></div></div>`

        this._infowindow.setContent(content);
        this._infowindow.open(this.map(), marker);

      //  this.showPanel(placeResult);
      } else {
        console.log('showDetails failed: ' + status);
      }
    }

    //Step 4D: Load place details in a sidebar */
    // Displays place details in a sidebar
//       showPanel(placeResult) {
//       // If infoPane is already open, close it
//       if (infoPane.classList.contains("open")) {
//         infoPane.classList.remove("open");
//       }
// }
  delete_marker(array){
    $.each(array, function( index, marker ) {
      marker.setVisible(false)
    });
    this._infowindow.close();
    //array = []
  }
  show_marker(array){
    // console.log(array)
    $.each(array, function( index, marker ) {
      // console.log( index + ": " + marker );
      marker.setVisible(true)
    });

  }




}
