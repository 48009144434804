//property detail images slider
function setflash_time(){
  setTimeout(function() {
    $('.alert').hide();
  }, 10000);
}


function updateQueryStringParameter(uri, key, value) {
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf('?') !== -1 ? "&" : "?";
  if (uri.match(re)) {
  return uri.replace(re, '$1' + key + "=" + value + '$2');
  }
  else {
  return uri + separator + key + "=" + value;
  }
}
setflash_time()
$(document).on('submit','#create_payment_form_tab',function(e){
  $(document).find(".loader-outer").removeClass("d-none")
  $(document).find(".loader-outer").addClass("d-block")
})

$(document).on('change', "#filter_property_address, #sort_property_address, #modal_property_address",function(e){
  $("#filter_maxLat").val("")
  $("#modal_maxLat").val("")
  $("#sort_maxLat").val("")
  $("#filter_maxLng").val("")
  $("#modal_maxLng").val("")
  $("#sort_maxLng").val("")
  $("#filter_minLat").val("")
  $("#modal_minLat").val("")
  $("#sort_minLat").val("")
  $("#filter_minLng").val("")
  $("#modal_minLng").val("")
  $("#sort_minLng").val("")
  if($(this).val() != ''){
    $('#filter_property_address').val($(this).val())
    $('#sort_property_address').val($(this).val())
    $('#modal_property_address').val($(this).val())
  }else{
    $('#filter_property_address').val('Puerto Rico')
    $('#sort_property_address').val('Puerto Rico')
    $('#modal_property_address').val('Puerto Rico')
  }

 //console.log($("#filter_maxLat").val())
})

$(document).on('change','.submit_search_button',function(e){
  if($("#filter_search_property_form").valid() && $("#modal_search_property_form").valid()){
    // $("#hidden_property_list_type").val($(this).attr('data-value'))
    // $("#filter_hidden_property_list_type").val($(this).attr('data-value'))
    // $("#sort_hidden_property_list_type").val($(this).attr('data-value'))
    // $(this).closest('form').find('.search_property_button').click()
 }else{
   return false;
 }
})
// $(document).on('change','#en_content_ckeditor,#es_content_ckeditor',function(e){
//   console.log($(this).val())
// })
$(document).on('click','.back_button',function(e){
    e.preventDefault();
    div_class=$(this).attr('data-back_button')
    $(document).find('.common_back_div').hide()
    $(document).find("."+div_class).show()
    //console.log(div_class)
    $(document).find('.back_button').removeClass('active')
    $(this).addClass('active')
  })


$(document).on('change', '#filter_max_price, #modal_max_price, #filter_min_price, #modal_min_price, #modal_bedroom, #filter_bedroom, #modal_bathroom,#filter_bathroom,#filter_property_details,#modal_property_details,#filter_property_status,#modal_property_status,#filter_property_type,#modal_property_type',function(e){

  id = $(this).attr('data-change_id')
  // console.log(id)
  $("#"+id).val($(this).val())
  // console.log($("#"+id).val())
})
$(document).on('click','.search_property_button',function(e){
 $('#filter-modal').modal('hide')
})
$(document).on('click','.cancel_subcription_link', function(e){
  e.preventDefault();
  action = $(this).attr("data-action")
  message = $(this).attr('data-message')
  $("#modal_subcribe_message").modal('show')
  $(".modal_subcribe_message_body").html(message)
  $(".delete_subcription_confirmation").attr('data-action',action)
  $(document).find("form#subcription_cancel_submit").attr('action',action)
  $(document).find("form#subcription_cancel_submit").attr('method',"post")
  X_CSRF_Token= $('meta[name="csrf-token"]').attr('content')
  $("#subcription_authenticity_token").val(X_CSRF_Token)
  //$("#subcription_authenticity_token").val(X_CSRF_Token)

})

$(document).on('click','.delete_subcription_confirmation', function(e){
  e.preventDefault();
  //console.log('url')
  url = $(this).attr('data-action')
  if(url){

      $("form#subcription_cancel_submit").submit()
  }

})

$(document).on("click", ".click_back_button",  function(e){
  e.preventDefault();
  back_option=$(this).attr('data-back_option')
  $(window).scrollTop(0);
  if(back_option == "property_detail_div"){
    $(document).find(".property_detail_div").hide()
    $(document).find(".property_info_div").show()
    $(document).find('.back_button').removeClass('active')
    $(document).find('.prty_info').addClass('active')
  }
  else if(back_option == 'property_gallery_div'){
    $(document).find(".property_gallery_div").hide()
    $(document).find(".property_detail_div").show()
    $(document).find('.back_button').removeClass('active')
    $(document).find('.prty_detail').addClass('active')
  }
})
$(document).on('click', ".delete_property_gallery", function(e){
  e.preventDefault();
  $(document).find('#delete_confirmation').modal('show')
  $(document).find('.delete_confirmation_body').html(delete_message_to_gallery_image)
  $(document).find('.delete_confirmation_ok').attr("data-attachment_id",$(this).attr('data-attachment_id'))
//  console.log($(this).attr('data-attachment_id'))
})
$(document).on("click", ".delete_confirmation_ok", function(e){
  e.preventDefault();
  property_id = $("#property_id").val()
  //console.log($(this).attr('data-attachment_id'))
  attachment_id = $(this).attr('data-attachment_id')
  $(document).find(".loader-outer").removeClass("d-none")
  $(document).find(".loader-outer").addClass("d-block")
  $.ajax({
  url:'/properties/delete_property_gallery_images/'+attachment_id+"/"+property_id,
  type:'delete',
  headers: {
  'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
  },
  success:function(response){
    $(document).find('#delete_confirmation').modal('hide')
    if(response.status){
        // $('#main_preview_div').load(window.location.href + ' #preview_div',function(){
        //   sorting_image()
        //   $(document).find(".loader-outer").removeClass("d-block")
        //   $(document).find(".loader-outer").addClass("d-none")
        // })
        $('#main_preview_div').html(response['html_data'])
        sorting_image()
        $(document).find(".loader-outer").removeClass("d-block")
        $(document).find(".loader-outer").addClass("d-none")

    }else{
      alert("There is error")
    }
  }
})
})
$(document).on('change','.property_type_id_class',function(e){
  // if($(this).attr('id') == "House"){
  //   $(document).find(".parking_back_to_back_class").hide()
  //   $(document).find(".parking_side_by_side_class").hide()
  //   $(document).find("#parking_back_to_back").hide()
  //   $(document).find("#parking_back_to_back").prop("checked",false)
  //   $(document).find("#parking_side_by_side").hide()
  //   $(document).find("#parking_side_by_side").prop("checked",false)
  //
  // }else{
  //   $(document).find("#parking_back_to_back").show()
  //   $(document).find("#parking_side_by_side").show()
  //   $(document).find(".parking_back_to_back_class").show()
  //   $(document).find(".parking_side_by_side_class").show()
  // }
  this_tag = $(this)
  $.ajax({
    url: "/fetch_property_detail",
    data: {property_type_id: $(this).val(),property_id: $("#property_id").val()},
    type: "get",
    headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
    },
    success: function(response){
      if(response['status']){

          $(".add_amenties_to_property").html(response['html_data'])
          $(".add_common_amenties_to_property").html(response['common_html_data'])
          if(this_tag.attr('id') == "Commercial" || this_tag.attr('id') == "Comercial" || this_tag.attr('id') == "Solar/Lote"
          || this_tag.attr('id') == "Land/Lot" ){
            $(".hide_show_bedroom").hide()

            $("#property_detail_bedrooms").hide()
            $("#property_detail_bedrooms").val('')
            if(this_tag.attr('id') == "Commercial" || this_tag.attr('id') == "Comercial" )
            {
              $(".hide_show_bathroom").show()
              $("#property_detail_bathrooms").show()
              $("#property_detail_bathrooms").val('')
              $(".hide_show_required").hide()
              $(".common_property_bathroom_not_com").removeClass('property_bathroom_not_com')
              $('#property_detail_bathrooms-error').hide()
            }else{
              $(".hide_show_bathroom").hide()
              $("#property_detail_bathrooms").hide()
              $("#property_detail_bathrooms").val('')

            }
          }else{
            $(".hide_show_bedroom").show()
            $(".hide_show_bathroom").show()
            $("#property_detail_bedrooms").show()
            $("#property_detail_bedrooms").val('')
            $("#property_detail_bathrooms").show()
            $("#property_detail_bathrooms").val('')
            $(".hide_show_required").show()
            $(".common_property_bathroom_not_com").addClass('property_bathroom_not_com')
          }
      }else{
        console.log('error')


      }
    }

  })
})
  // $('#property_price').numeric({decimal : ".",  negative : false, scale: 3, decimalPlaces: 2});
$(document).on('change', ".list_type_class", function(e){
  if($(this).attr('id') == "for_rent" || $(this).attr('id') == "en_renta"){
    $(document).find(".property_status_div").hide()
    $(document).find(".property_status_class").hide()
    $(document).find(".propert_utility_class").show()
    $(document).find(".propert_utility_div").show()
    // $(document).find(".propert_utility_class_label").show()
    // $(document).find(".propert_utility_class_span").show()

    $(document).find(".property_status_class").prop('checked',false)

  }else{
    $(document).find(".property_status_div").show()
    $(document).find(".property_status_class").show()
    $(document).find(".propert_utility_class").hide()
    $(document).find(".propert_utility_div").hide()
    $(document).find(".propert_utility_class option:selected").removeAttr('selected')

  }
})
  // $(document).on('click','.')
  // alert(flash_message_delete_global_success)

$(document).on("click",".save_property",function(e){
e.preventDefault();
this_tag = $(this)
property_id = $(document).find("#property_id").val()
property_sold_status = $(document).find('input[name="property[property_sold_status]"]:checked').val()
console.log(property_sold_status)
if($(document).find('form#new_property').valid()){
  $.ajax({
  url:'/properties/save_property',
  type:'post',
  data: $(this).closest("form").serialize()+"&property_id="+property_id,
  headers: {
  'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
  },
  success:function(response){
    if(response.status){

      // $(document).find("#upload_property_id").val(response.property_id)
      if(property_sold_status == 'true'){
        window.location.href = window.location.origin+"/properties/property_list"
      }else{
        $(window).scrollTop(0);
        $(document).find(".prty_info").addClass('back_button')
        $(document).find(".prty_detail").addClass('back_button')
        $(document).find(".prty_info").removeClass('active')
        $(document).find(".prty_detail").addClass('active')
        $(document).find(".property_info_div").hide()
        $(document).find(".property_detail_div").show()
        $(document).find("#property_id").val(response.property_id)
        $(document).find('.upgrade_link').attr("href",response.url)
      $('#ajax_flash').show().html("").html('<div class="slide-effect"><div class="alert alert-success alert-dismissible"><button type="button" class="close" data-dismiss="alert" aria-hidden="true"><i class="fas fa-times"></i></button>  <div class="alert-message p-med"><i class=" fa fa-check"></i> '+flash_message_delete_global_success+'</div>'+response.messages+' </div></div>');

      setflash_time()
    }
      // this_tag.addClass("edit_property")
      // this_tag.removeClass("save_property")
      // url = window.location.href;
      // new_url = url.replace("/property_info","/property_detail")
      // urlChanageAjax(new_url)
    }else{
      $('#ajax_flash').show().html("").html('<div class="slide-effect"><div class="alert alert-danger alert-dismissible"><button type="button" class="close" data-dismiss="alert" aria-hidden="true"><i class="fas fa-times"></i></button> <div class="alert-message p-med"><i class=" fa fa-ban"></i> '+ flash_message_delete_global_alert+'</div>'+response.messages+' </div></div>');
      setflash_time()
    }
  }
})
}
})
$(document).on("click",".save_property_detail",function(e){
e.preventDefault();
this_tag = $(this)
property_id = $(document).find("#property_id").val()
//console.log(!($("#property_detail[property_size_id]-error").is(":hidden")))
hide_property_size = false
hide_property_size_id = false
if($(".property_detail_property_size_id:checked").val() == undefined && ($("#property_detail_size").val() != '' )){
  hide_property_size_id = true
}
if($(".property_detail_property_size_id:checked").val() != undefined && ($("#property_detail_size").val() == '' )){
  hide_property_size = true
}
console.log($(document).find('form#new_property_detail').valid() && ( ($("#property_detail\\[property_size_id\\]-error").is(":hidden") )  && ($("#property_detail\\[size\\]-error").is(":hidden"))))
console.log($("#property_detail\\[size\\]-error:hidden"))
console.log($("#property_detail\\[property_size_id\\]-error:hidden"))

if($(document).find('form#new_property_detail').valid() && ( !hide_property_size &&  !hide_property_size_id ) ){
  $.ajax({
  url:'/properties/save_property_detail',
  type:'post',
  data: $(this).closest("form").serialize()+"&property_id="+property_id,
  headers: {
  'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
  },
  success:function(response){
    if(response.status){
      $(window).scrollTop(0);
      $(document).find(".prty_detail").addClass('back_button')
      $(document).find(".prty_gallery").addClass('back_button')
      $(document).find(".prty_detail").removeClass('active')
      $(document).find(".prty_gallery").addClass('active')
      $(document).find(".property_detail_div").hide()
      $(document).find(".property_gallery_div").show()
      $(document).find("#property_detail_id").val(response.property_detail_id)
      $('#ajax_flash').show().html("").html('<div class="slide-effect"><div class="alert alert-success alert-dismissible"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button><div class="alert-message p-med"><i class=" fa fa-check"></i> '+flash_message_delete_global_success+'</div>'+response.messages+' </div></div>');
      url = window.location.href;
      setflash_time()
      // new_url = url.replace("/property_info","/property_detail")
      // urlChanageAjax(new_url)
    }else{
      $('#ajax_flash').show().html("").html('<div class="slide-effect"><div class="alert alert-danger alert-dismissible"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button><div class="alert-message p-med"><i class=" fa fa-ban"></i> '+flash_message_delete_global_alert+'</div>'+response.messages+' </div></div>');
      setflash_time()
    }
  }
})
}
else{
  if(hide_property_size){
     $(document).find("#property_detail\\[size\\]-error").html(validate_property_size_global).show()
  }
  if(hide_property_size_id){
    $(document).find("#property_detail\\[property_size_id\\]-error").html(validate_property_size_id_global).show()
  }
}
})
  // $(document).on("click",".save_property_gallery",function(e){
  //   e.preventDefault();
  //   // if($(document).find('form#new_property_gallery').valid()){
  //   //   $(document).find('form#new_property_gallery').submit()
  //   // }
  // })
  $(document).on('change','.sold_status_list_type_class',function(e){
    console.log($(this).val())
    if($(this).val() == 'true'){
      $(document).find('.prty_detail').hide()
      $(document).find('.prty_detail').removeClass('back_button')
      $(document).find('.prty_gallery').hide()
      $(document).find('.prty_gallery').removeClass('back_button')
      $(document).find('.step-box li  a').addClass('remove_link_content')
      // .step-box li a:after{content:none;}
    }else{
      $(document).find('.prty_detail').show()
      $(document).find('.prty_detail').addClass('back_button')
      $(document).find('.prty_gallery').show()
      $(document).find('.prty_gallery').addClass('back_button')
      $(document).find('.step-box li  a').removeClass('remove_link_content')
    }
  })

$(document).on("click",".save_property_gallery",function(e){
  e.preventDefault();
  // this_tag = $(this)
  property_id = $("#property_id").val()
  if($(document).find('form#new_property_gallery').valid()){
    $.ajax({
    url:'/properties/save_property_gallery',
    type:'post',
    data: $(this).closest("form").serialize()+"&property_id="+property_id,
    headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
    },
    success:function(response){
      if(response.status){
        $(window).scrollTop(0);

        $(document).find('#property_done').modal('show')
        $(document).find('#exclusive_new_upgrade_link').attr('href', response['exclusive_url'])
        $(document).find('#prime_new_upgrade_link').attr('href', response['prime_url'])
        //$(document).find(".prty_gallery").addClass('back_button')

        // $(document).find(".property_detail_div").hide()
        // $(document).find(".property_gallery_div").show()
        // $(document).find("#property_detail_id").val(response.property_detail_id)
        //$('#ajax_flash').show().html("").html('<div class="slide-effect"><div class="alert alert-success alert-dismissible"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>'+response.messages+' </div></div>');
      //  window.location.href = window.location.origin+"/user/dashboard"
        // new_url = url.replace("/property_info","/property_detail")
        // urlChanageAjax(new_url)
      }else{
        $('#ajax_flash').show().html("").html('<div class="slide-effect"><div class="alert alert-danger alert-dismissible"><button type="button" class="close" data-dismiss="alert" aria-hidden="true"><i class="fas fa-times"></i></button>  <div class="alert-message p-med"><i class=" fa fa-ban"></i> Alert!</div>'+response.messages+' </div></div>');
        setflash_time()
      }
    }
  })
  }
})

$(document).on("click", ".property_list_type",function(e){
    e.preventDefault();
    $(document).find('.property_list_type').removeClass('active')
    $(this).addClass('active')
    $("#hidden_property_list_type").val($(this).attr('data-value'))
    $("#filter_hidden_property_list_type").val($(this).attr('data-value'))
    $("#sort_hidden_property_list_type").val($(this).attr('data-value'))
    $('.submit_search_button').attr('data-value',$(this).attr('data-value'))
    //$(this).closest('form').find('.search_property_button').click()
    //$(document).find('.search_property_button')[0].click()

  })

$(document).on('click', '.search_filter_form ', function (e) {
   e.stopPropagation();
 })

$(document).on('click','select.sort_by_price_button ',function(e){
  $("#hidden_property_list_type").val($(this).attr('data-value'))
  $("#filter_hidden_property_list_type").val($(this).attr('data-value'))
  $("#sort_hidden_property_list_type").val($(this).attr('data-value'))
  $(this).closest('form').find('.search_property_button').click()
})

    //
    // $(document).on('submit','',function(e){
    //   $(document).find(".loader-outer").removeClass("d-none")
    //   $(document).find(".loader-outer").addClass("d-block")
    // })

    $(document).on("focusout","#lead_full_name,#lead_email ",function(e){
      property = $(document).find('.property_name_for_lead').text()
      property_location= $(document).find(".property_location_for_lead").text()
      if(property != ''){
        if($(document).find('#lead_email').hasClass('en_class')){
          $(document).find("#lead_message").html("I am interested in "+property+" "+property_location)
        }else{
          $(document).find("#lead_message").html("Estoy interesado en el nombre de esta propiedad "+property+" "+property_location)
          //+"and RSVP a la jornada de puertas abiertas de la propiedad.
          //+"and  RSVP to the open house of the property.
        }
      }else{
        property = $(document).find('#property_name_for_rsvp').val()
        property_location= $(document).find("#property_location_for_rsvp").val()
        current_user = $(document).find("#current_user_for_rsvp").val()
        //link = $(document).find("#property_link_for_rsvp a").attr('href')
        //console.log(link)
        if($(document).find('#lead_email').hasClass('en_class')){
          $(document).find("#lead_message").html(`${current_user} has RSVP to ${property} ${property_location} open house.`)
        }else{
          $(document).find("#lead_message").html(`${current_user} ha RSVP a ${property} ${property_location} open house.`)
        }
      }
    })


    $(document).on('click',".map_list_view_button", function(e){
      map_list_val = $(this).attr("data-map_list_val")
      $("#sort_map_list_view").val(map_list_val)
      $("#filter_map_list_view").val(map_list_val)
      $("#map_list_view").val(map_list_val)
      // console.log('map_list_val: '+map_list_val)

      if(map_list_val == '1'){
        if(!($('.chnage_class_map_list_view').hasClass('search-page-list'))){
          $('.chnage_class_map_list_view').removeClass('search-page-map')
          $('.chnage_class_map_list_view').addClass('search-page-list')
          //  $(document).find('.search_property_button').click()
            reload_property_prime_slider()
        }
      }else{

        if(!($('.chnage_class_map_list_view').hasClass('search-page-map'))){
          $('.chnage_class_map_list_view').removeClass('search-page-list')
          $('.chnage_class_map_list_view').addClass('search-page-map')
          reload_property_prime_slider()
        }


      }


    })

  $(document).on('ajax:beforeSend', '#search_property_form, #property_list_search_form, .pagination > a,#find_agent_form, #edit_user, #save_lead_contact_form, #filter_search_property_form, #sort_search_property_form, #modal_search_property_form', function(event, data, status, xhr) {
      $(document).find(".loader-outer").removeClass("d-none")
      $(document).find(".loader-outer").addClass("d-block")
  });
  // $(document).on('ajax:beforeSend', '#search_property_form, #property_list_search_form, .pagination > a', function(event, data, status, xhr) {
  //     $(document).find(".loader-outer").removeClass("d-none")
  //     $(document).find(".loader-outer").addClass("d-block")
  // });

  $(document).on('click',".get_map_modal", function(e){
    e.preventDefault();
    $(document).find("#google_modal").modal('show')
  })

  // $.ajaxSetup({
  //           headers: {
  //                   'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
  //           },
  //           beforeSend: function() {
  //
  //             $(document).find(".loader-outer").removeClass("d-none")
  //             $(document).find(".loader-outer").addClass("d-block")
  //           },
  //           complete: function() {
  //             $(document).find(".loader-outer").addClass("d-none")
  //             $(document).find(".loader-outer").removeClass("d-block")
  //           },
  //           success: function() {
  //             $(document).find(".loader-outer").addClass("d-none")
  //             $(document).find(".loader-outer").removeClass("d-block")
  //           },
  //           error: function(xhr, ajaxOptions, thrownError) {
  //                   $(document).find(".loader-outer").removeClass("d-block")
  //
  //           }
  //   });
  // $(document).on('submit', "#search_property_form", function(e){
  //   $(document).find(".loader-outer").removeClass("d-none")
  //   $(document).find(".loader-outer").show()
  // })
  // upload_dropzone()
  // Dropzone.options.filesField = {
  //   url: "/property_gallery_images_uploads",
  //   autoProcessQueue: false,
  //   uploadMultiple: true,
  //   acceptedFiles: "image/*",
  //   accept: function(file, done) {
  //     $("div#files-field").css({"height": "auto"});
  //     done();
  //   },
  //   init: function() {
  //     var myDropzone = this;
  //     myDropzone.on("sending", function(file, xhr, formData) {
  //       formData.append("property_id", $("#property_id").val() );
  //     });
  //   },
  //   success: function(data,response) {
  //     alert(response);
  //   }
  // };
  // Dropzone.autoDiscover = false;
  var minImageWidth = 800;
  var minImageHeight = 600;
  var maxfileSize = 4
var new_dropzone =  Dropzone.options.filesField = {
                url: "/property_gallery_images_uploads",
                headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                },
                previewContainer: false,
                acceptedFiles: "image/jpeg,image/png,image/jpg",
                thumbnailWidth: minImageWidth,
                thumbnailHeight: minImageHeight,
                // uploadMultiple: true,
                // parallelUploads: 4,
                // autoProcessQueue: false,
                // autoQueue: false,
                // addRemoveLinks: true,
                accept: function(file, done) {
                 file.acceptDimensions = done;
                 file.rejectDimensions = function() { done("Image width or height too small.");
                 // $('#property_gallery_images-error').html(error).show()
                };
              },
  	            init: function() {
                  myDropzone = this
                  var max_min_size = 0
                  var atmost_size = 0
                  var number_of_image = 0
                  // document.querySelector(".save_property_gallery_image").addEventListener("click", function(e) {
                  //   // const acceptedFiles = myDropzone.getAcceptedFiles();
                  //   //  for (let i = 0; i < acceptedFiles.length; i++) {
                  //        myDropzone.processFile()
                  //    //}
                  // });

                  // this.on("queuecomplete", function (file) {
                  //   alert("all files uploaded successfully");
                  // });
                  this.on("error", function (file, message) {
                      // console.log(file.type)
                    $(document).find(".loader-outer").addClass("d-none")
                    $(document).find(".loader-outer").removeClass("d-block")
                    if(file.type != "image/jpeg" && file.type != "image/png" && file.type != "image/jpg" ){
                      $('#property_gallery_images-error').append(file.name+' : '+validate_image_file+"<br>").show()
                    }
                    this.removeFile(file);

                  //  $('#property_gallery_images-error').html(validate_gallery_image_size).show()
                    });
                  this.on("thumbnail", function(file) {
                    // console.log(file.type)
                   if (file.width < minImageWidth || file.height < minImageHeight) {

                     file.rejectDimensions();
                    $('#property_gallery_images-error').append(file.name+' : '+validate_gallery_dimension+"<br>").show()
                   }else{

                     if(file.size > 1024*1024*5/*2MB*/)
                    {
                    $('#property_gallery_images-error').append(file.name+' : '+validate_gallery_image_size+"<br>").show()
                    //  file.rejectDimensions(validate_gallery_image_size
                      file.rejectDimensions();
                    }else{
                      file.acceptDimensions();


                    }
                  }


                 })
                 this.on("addedfile", function(file) {
                 //  if(this.files.length<=2){
                 // //   enqueueFile(file);
                 // // }
                 //  myDropzone.enqueueFile(file)
                 //console.log(myDropzone.getQueuedFiles().length)
                  //myDropzone.processQueue();
                  // dacceptedFiles = myDropzone.getAcceptedFiles();
                 $(file.previewTemplate).remove()
                 $(document).find(".loader-outer").removeClass("d-none")
                 $(document).find(".loader-outer").addClass("d-block")
               });
               this.on('complete',function(file){
              //   console.log(this.files)
               });
             this.on("sending", function(file, xhr, formData) {
                   formData.append("property_id", $("#property_id").val() );
                   $(document).find(".loader-outer").removeClass("d-none")
                   $(document).find(".loader-outer").addClass("d-block")

                 });
             this.on('success',function(data,response){
               // console.log(response)
               // console.log(data.previewElement)
               $(document).find("#preview_div").append(data.previewTemplate)
               $(data.previewElement).find('.delete_property_gallery').attr("href"," ")
               $(data.previewElement).find('.delete_property_gallery').attr("data-attachment_id",response.attachment_id)
               $(document).find(".loader-outer").addClass("d-none")
               $(document).find(".loader-outer").removeClass("d-block")
             });


  				},
  				previewTemplate:`<div class="col-lg-3 col-md-4 col-sm-4 col-6 col dz-preview dz-file-preview ui-widget-content draggble_photo_gallery first-preview">
  								<div class="photo-box position-relative ">
  									<img data-dz-thumbnail class="radius-10" width="150" height="100" alt="Property Images" />
                    <a  class="icon remove-icon delete_property_gallery" rel="nofollow"  href=" "></a>
  								</div>
  							</div>`,
  };



  $(document).on("click", '.delete_property_gallery_dropzone',function(e) {
    // e.preventDefault();
    // e.stopPropagation();
    console.log($(this).parents('.parent_preview_div'))
    $(this).parents('.parent_preview_div').remove()
  });
  $(document).on('click','.like_button',function(e){
    e.preventDefault();
    this_tag = $(this)
    var property_id = $(this).attr("data-property_id")
    $(document).find(".loader-outer").removeClass("d-none")
    $(document).find(".loader-outer").addClass("d-block")
    $.ajax({
    url:'/properties/like_dislike',
    data: {property_id: property_id},
    type:'get',
    headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
    },
    success:function(response){

      if(response['status']){


        if(window.location.pathname.includes('favourite_properties')){
          $(document).find(".main_fav_div").load(window.location.href + ' .inner_fav_div',function(){
            if(response['like_dislike_status']){
              this_tag.find('i').addClass('far')
              this_tag.find('i').removeClass('fas')
              this_tag.attr("title", "Unfavourite")
            }else{
              this_tag.find('i').addClass('fas')
              this_tag.find('i').removeClass('far')
              this_tag.attr("title", "Favourite")
            }
            $(document).find(".loader-outer").removeClass("d-block")
            $(document).find(".loader-outer").addClass("d-none")
          })
        }else{
          if(response['like_dislike_status']){
            this_tag.find('i').addClass('far')
            this_tag.find('i').removeClass('fas')
            this_tag.attr("title", "Unfavourite")
          }else{
            this_tag.find('i').addClass('fas')
            this_tag.find('i').removeClass('far')
            this_tag.attr("title", "Favourite")
          }
          $(document).find(".loader-outer").removeClass("d-block")
          $(document).find(".loader-outer").addClass("d-none")
        }


      }else{
        if(response.url){
          if($(document).find(".signin_popup").length > 0 ){
            $(document).find(".signin_popup")[0].click()
            $('#ajax_flash').show().html("").html('<div class="slide-effect"><div class="alert alert-danger alert-dismissible"><button type="button" class="close" data-dismiss="alert" aria-hidden="true"><i class="fas fa-times"></i></button>  <div class="alert-message p-med"><i class=" fa fa-ban"></i> Alert!</div>'+response.message+' </div></div>');
            setflash_time()
          }
          //window.location.href=response.url;
        }else{
          $('#ajax_flash').show().html("").html('<div class="slide-effect"><div class="alert alert-danger alert-dismissible"><button type="button" class="close" data-dismiss="alert" aria-hidden="true"><i class="fas fa-times"></i></button>  <div class="alert-message p-med"><i class=" fa fa-ban"></i> Alert!</div>'+response.message+' </div></div>');
          setflash_time()
        }
      }
    }
  })
  })

  $(document).on('click', ".delete_property_list", function(e){
    e.preventDefault();
    $(document).find('#delete_confirmation_property_list').modal('show')
    $(document).find('.delete_confirmation_property_list_body').html($(this).attr('data-message'))
    $(document).find('.delete_confirmation_common').attr('data-id',$(this).attr('data-id'))
    $(document).find('.delete_confirmation_common').attr('data-model_name',$(this).attr('data-model_name'))
    $(document).find('.delete_confirmation_common').attr('data-model_column',$(this).attr("data-model_column"))
  })

  $(document).on('click', '.delete_confirmation_common', function () {

          var model_id = $(this).attr('data-id')
          var model_name = $(this).attr('data-model_name')
          var model_column = $(this).attr("data-model_column")
          if (model_id != '' && model_name != '' && model_column != '') {
              common_delete(model_id, model_name,model_column);
          }
  })
  $(document).on('click', '.view_lead_detail_popup', function (e) {
    e.preventDefault();
    console.log("got hit")
    var lead_id = $(this).attr('data-lead_id')
    $.ajax({
      type: "get",
      url: "/view_lead_detail",
      data:{lead_id: lead_id},
      headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
      success: function(response){
        if(response.status){
          $(document).find("#show_lead_modal").modal('show')
          $(document).find(".show_lead_modal_body").html(response.data)

        }
      }
    })
  })






  function common_delete(model_id, model_name,model_column) {
    $(document).find(".loader-outer").removeClass("d-none")
    $(document).find(".loader-outer").addClass("d-block")
      $.ajax({
          type: 'post',
          url: '/common_delete',
          data: {"model_id": model_id, "model_name": model_name, "model_column": model_column, authenticity_token: $('[name="csrf-token"]')[0].content},
          dataType: 'json',
          success: function (response) {
            $(document).find('#delete_confirmation_property_list').modal('hide')
              if (response.status) {
                $('.main_table').load(window.location.href + ' .inner_table', function(){
                  $('#ajax_flash').show().html("").html('<div class="slide-effect"><div class="alert alert-success alert-dismissible"><button type="button" class="close" data-dismiss="alert" aria-hidden="true"><i class="fas fa-times"></i></button>  <div class="alert-message p-med"><i class=" fa fa-check"></i> '+flash_message_delete_global_success+'</div>'+response.messages+' </div></div>');
                  setflash_time()
                  $(document).find(".loader-outer").addClass("d-none")
                  $(document).find(".loader-outer").removeClass("d-block")
                })
                //window.location.href = window.location.href
                setTimeout(function() {

              }, 10000);



              } else {
                $('#ajax_flash').show().html("").html('<div class="slide-effect"><div class="alert alert-danger alert-dismissible"><button type="button" class="close" data-dismiss="alert" aria-hidden="true"><i class="fas fa-times"></i></button>  <div class="alert-message p-med"><i class=" fa fa-ban"></i> Alert!</div>'+response.messages+' </div></div>');
                setflash_time()
              }

          }
      })

  }
  $(document).on('mouseup', '.modal_update_lead_status', function(e){
    console.log($(this).closest('td').find('input[type=radio]:checked'))
    $(document).find('#modal_change_status_message').modal('show')
    $(document).find('.update_lead_status').attr('data-val',$(this).val())
    $(document).find('.update_lead_status').attr('data-url',$(this).attr('data-url'))
    $(this).closest('td').find('input[type=radio]:checked').addClass('change_radio_id_for_dismiss')

  })
  // $(document).on('change', '.modal_update_lead_status', function(e){
  //   $(document).find('#modal_change_status_message').modal('show')
  //   $(document).find('.update_lead_status').attr('data-val',$(this).val())
  //   $(document).find('.update_lead_status').attr('data-url',$(this).attr('data-url'))
  //
  // })
  $(document).on('click', '.change_radio_value', function(e){
    $(document).find('.change_radio_id_for_dismiss').prop("checked", true)
    $(document).find('.change_radio_id_for_dismiss').removeClass('change_radio_id_for_dismiss')
  })
  $(document).on('click', '.update_lead_status', function(e){
    e.preventDefault();
    $(document).find(".loader-outer").removeClass("d-none")
    $(document).find(".loader-outer").addClass("d-block")
    lead_status=$(this).attr('data-val')
    $.ajax({
      url: $(this).attr('data-url'),
      type: "get",
      headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
      data: {lead_status: lead_status},
      success: function(response){
        $(document).find('#modal_change_status_message').modal('hide')
        $(document).find('.change_radio_id_for_dismiss').removeClass('change_radio_id_for_dismiss')
        if(response.status){
          $('.main_table').load(window.location.href + ' .inner_table', function(){
            $('#ajax_flash').show().html("").html('<div class="slide-effect"><div class="alert alert-success alert-dismissible"><button type="button" class="close" data-dismiss="alert" aria-hidden="true"><i class="fas fa-times"></i></button>  <div class="alert-message p-med"><i class=" fa fa-check"></i> Success!</div>'+response.messages+' </div></div>');

            $(document).find(".loader-outer").addClass("d-none")
            $(document).find(".loader-outer").removeClass("d-block")
            $(document).find(".lead_sold_count").html(response.lead_sold_count)
            setflash_time()
          })
        }else{
          $('#ajax_flash').show().html("").html('<div class="slide-effect"><div class="alert alert-dander alert-dismissible"><button type="button" class="close" data-dismiss="alert" aria-hidden="true"><i class="fas fa-times"></i></button>  <div class="alert-message p-med"><i class=" fa fa-check"></i> Alert!</div>'+response.messages+' </div></div>');
          setflash_time()

        }
      }

    })
  })


  $(document).on("click",".plan_select_button", function(e){
    e.preventDefault();
    plan_id= $(this).attr("data-plan_id")
    property_id = $(this).attr("data-property_id")
    this_tag = $(this)
    $(document).find(".loader-outer").removeClass("d-none")
    $(document).find(".loader-outer").addClass("d-block")
    $.ajax({
      url: "/plans/update_user_select_plan",
      data:{plan_id: plan_id, property_id: property_id},
      headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
      },
      type: "get",
      success: function(response){
        $(document).find(".loader-outer").addClass("d-none")
        $(document).find(".loader-outer").removeClass("d-block")
        if(response.status){
          if(response.select){
            this_tag.attr("title", add_plan_selected_text)
            this_tag.text(add_plan_selected_text)
            this_tag.closest('.check_select_plan_row').addClass('selected-plan')
            this_tag.closest('.check_select_plan_row').removeClass('check_select_plan_row')
          }else{
            this_tag.attr("title", add_plan_select_text)
            this_tag.text(add_plan_select_text)
            this_tag.closest('.selected-plan').addClass('check_select_plan_row')
            this_tag.closest('.selected-plan').removeClass('selected-plan')
          }

        }else{

        }
      }
    })
  })

  $(document).on('change',"#card_number",function(e){

        var card_number = $(this).val();
        var cardType = $.payment.cardType(card_number);
        $(document).find('#card_brand').text(cardType);
        check_type = (!$.payment.validateCardNumber(card_number))
        console.log(check_type == true)
        if(check_type == true){
          $(document).find('#card_number-error').html(validate_card);
          $(document).find('#card_number-error').show();
        }else{
          $(document).find('#card_number-error').hide();
        }


      })
$(document).on('change','#card_full_name',function(e){
  $("#billing_name").val($(this).val())
})

$(document).on('change',"#card_cvv",function(e){

  var cardType = $.payment.cardType($(document).find('#card_number').val())
  check_type = (!$.payment.validateCardCVC($(this).val(), cardType))
  //$(document).find('#card_cvv-error').toggleInputError(check_type);
  if(check_type == true){
    $(document).find('#card_cvv-error').html(validate_cvv);
    $(document).find('#card_cvv-error').show();
  }else{
    $(document).find('#card_cvv-error').hide();
  }
})

//
// <input type="text" data-tagline />
// <div class="dz-size" data-dz-size></div>
$(document).on('turbolinks:load', function() {
  //initChatroomCable()
//upload_dropzone()
// $(document).find('#property_details').multiselect({
//           includeSelectAllOption: true,
//           click: function(event, ui){
//       $callback.text(ui.value + ' ' + (ui.checked ? 'checked' : 'unchecked') );
//    },
//         });
if(window.location.pathname.includes('search_property') ){
  //console.log($('#infinite-scrolling').length)
  // if($('#infinite-scrolling').length > 0){
  // #nav-list-tab[aria-selected="true"] {display: none;}
  // #nav-map-tab[aria-selected="true"] {display: none;}
  //console.log($('#nav-list-tab').attr('aria-selected'))
  // if($('#nav-list-tab').attr('aria-selected') == 'true'){
  //   $('#nav-list-tab[aria-selected="true"]').hide()
  // }
  // if($('#nav-map-tab').attr('aria-selected') == 'true'){
  //   $('#nav-map-tab[aria-selected="true"]').hide()
  // }

  $(document).scroll(function(e){
    var nearToBottom = 0;
    var round_height = $(window).scrollTop() + $(window).height()
    if (window.pagination_loading) {
      return;
    }

    var url = $('#infinite-scrolling').find('.pagination .next_page').attr('href').replace('go_top=1','go_top=0');
  //  console.log(url && round_height  > $(document).height() - 100)
    if(url && round_height  >  $(document).height() - 100) {
           // ajax call get data from server and append to the div


        var last = $('#infinite-scrolling').find('.pagination a').last().prev().attr('href');
      //   console.log(e)
        if (url) {

                // $('#load_more_property').text("Please Wait...");
                // $('#load_more_property').attr("title", "Please Wait...")
            // if (url==last) {
            //     $('#load_more_property').html('No More Records');
            //     $('#load_more_property').removeAttr("href");
            // }
                // var update_url = removeParam('map_list_view',url)
                // map_list_val = $("#sort_map_list_view").val()
                // new_url = update_url+"&map_list_view="+map_list_val
                // Variable to know that you are currently loading products
                 window.pagination_loading = true;
                 $('.loader-outer').removeClass('d-none').addClass('d-block');
                return $.getScript(url).always(function() {
                  $('.loader-outer').addClass('d-none').removeClass('d-block');
                  return window.pagination_loading = false;
                });

        } else {
            // $('#load_more_property').html("No More Records");
            // $('#load_more_property').removeAttr("href");
        }
      }
    });
  // }
  } // end of scroll page

  $.validator.addMethod("onlySpace", function(value, element) {
     return !(/^\s+$/.test(value));
  }, "Only spaces are not allowed");
  $('form#new_chat').validate({
     rules: {
         'chat[message]': {
              required: true,
              onlySpace: true
         }
     },
     messages: {
         'chat[message]': {
             required: required_message

         }
     }
  });
  $(document).find("#card_number").mask("9999 9999 9999 9999");
  if ($('.paginate_div .pagination').length > 0) {

    $('.paginate_div .pagination > a').attr('data-remote', 'true');

  }
  // var count_elements = $('.count_number_div').length;
  // if( count_elements > 1 ){
  // $("#search_property_count").html(count_elements+" "+search_page_counts_global)
  // }else
  // {
  //   $("#search_property_count").html(count_elements+" "+search_page_count_global)
  // }
  $.validator.addMethod("textOnly", function (value, element) {
             var pattern = /^[a-zA-Z\s]+$/
             return this.optional(element) || pattern.test(value);
    });
  $.validator.addMethod("alphaNumeric", function (value, element) {
            var pattern = /^[a-zA-Z0-9\s]+$/
            return this.optional(element) || pattern.test(value);
      });
   // custom method to prevent just spaces
  jQuery.validator.addMethod("trimFunc", function(value, element){
        val = value.trim();
        return this.optional(element) || val.length > 0;
  }, "Please enter valid value.");
  $.validator.addMethod('lessThanEqual', function(value, element, param) {
      console.log(param)
    return this.optional(element) || parseInt(value) <= parseInt(param);
  }, "The value  must be less than "+format_amount(100000000000));
  function format_amount(amount){
    var formatter = new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        });
    format_amount = formatter.format(amount);
    return  format_amount
  }
  jQuery.validator.addMethod("dollarsscents", function(value, element) {
      return this.optional(element) || /^\d{0,11}(\.\d{0,2})?$/i.test(value);
  }, "You must include two decimal places")            //  per_hour = per_hour.replace('NGN', '
  jQuery.validator.addMethod("creditcard",function (e, a, r) {return e.match(/^[0-9 \s]{19}$/);},validate_card)

  jQuery.validator.addMethod("valid_card",function (e,a) {return ($.payment.validateCardNumber(e))},validate_card)
  jQuery.validator.addMethod("valid_cvv",function (e,a) {
          var cardType = $.payment.cardType($(document).find('#card_number').val())
          return ($.payment.validateCardCVC(e,cardType))},validate_cvv)
  $.validator.addMethod("new_maxlength", function (value, element, len) {
     return value == "" || value.length <= len;
  });

  function validate_property(){
      $(document).find('form#new_property').validate({
      rules:{
        'property[list_type_id]':{
          required: true,
        },
        'property[property_type_id]':{
          required: true,
        },
        'property[property_status_id]':{
           // required: true,
        },
        'property[name]':{
          required: true,
          // alphaNumeric: true,
          minlength: 2,
          maxlength: 100,
          trimFunc: true,
          initialtrimFunc:  true,
        },
        'property[city]':{
          required: true,
          minlength: 2,
          maxlength: 100,
          trimFunc: true,
          initialtrimFunc:  true,
        },
        'property[zipcode]':{

          minlength: 2,
          maxlength: 6,
          trimFunc: true,
          initialtrimFunc:  true,
        },
        'property[location]':{
          required: true,
          minlength: 1,
          maxlength: 300,
          trimFunc: true,
          initialtrimFunc:  true,
        },
        'property[price]':{
          required: true,
          trimFunc: true,
          initialtrimFunc:  true,
          lessThanEqual: 100000000000,
          dollarsscents: true
        }
      },
      messages:{
        'property[list_type_id]':{
          required: required_list_type,
        },
        'property[property_type_id]':{
           required: required_property_type,
        },
        'property[property_status_id]':{
           // required: 'Please select property status',
        },
        'property[name]':{
          required: validate_property_name,
          minlength: property_name_minlength,
          maxlength: property_name_maxlength,
          // alphaNumeric: "Property name can accept alphanumeric value only",
          trimFunc: validate_property_name
        },
        'property[city]':{
          required: validate_required_city,
          minlength: validate_city_minlength,
          maxlength: validate_city_maxlength,
          trimFunc: validate_city,

        },
        'property[zipcode]':{
          minlength: validate_zipcode_minlength,
          maxlength: validate_zipcode_maxlength,
          trimFunc: validate_zipcode
        },
        'property[location]':{
          required: validate_location,
          minlength: location_minlength,
          maxlength: location_maxlength,
          trimFunc: validate_trim_location
        },
        'property[price]':{
          required: validate_required_price,
          trimFunc: validate_required_price,
          dollarsscents: validate_price
        }
      }
    })
      }
      //validate_required_bathroom
      $.validator.addMethod("selectRequried", $.validator.methods.required,
  validate_required_bathroom);
    jQuery.validator.addClassRules('property_bathroom_not_com',
      {  selectRequried: true});
  function validate_property_detail(){
    $(document).find('form#new_property_detail').validate({
      rules:{
        'property_detail[size]':{
          initialtrimFunc:  true,
          //check_required_input: 'property_detail[property_size_id]'
        },
        'property_detail[property_size_id]':{
        //  check_required_input: 'property_detail[size]'
        },
        'property_detail[bedrooms]':{
          required: true,
        },
        // 'property_detail[bathrooms]':{
        //   required: true,
        // },
        'property_detail[utility_id]':{
          required: true,
        },
        'property_detail[user_extra_properties_attributes][][extra_property_id]':{
          required: true,
        },
        'property_detail[property_description]':{
          minlength: 2,
          maxlength: 1300,
          initialtrimFunc:  true,
          trimFunc: true
        }
      },
      messages:{
        'property_detail[bedrooms]':{
          required: validate_required_bedroom,
        },
        // 'property_detail[bathrooms]':{
        //   required: validate_required_bathroom,
        // },
        'property_detail[utility_id]':{
          required: validate_required_utilities,
        },
        'property_detail[user_extra_properties_attributes][][extra_property_id]':{
          required: validate_required_property_details,
        },
        'property_detail[property_description]':{
          minlength: property_description_minlength,
          maxlength: property_description_maxlength,
          trimFunc: required_property_description
        }
      }
    })
  }
  function validate_property_gallery(){
    $(document).find('form#new_property_gallery').validate({
      rules:{

        'property_gallery[three_d_link]':{
          url: true,
        },
        'property_gallery[virtual_link]':{
          url: true,
        }
      },
      messages:{

        'property_gallery[three_d_link]':{
           url: required_3d_link,
        },
        'property_gallery[virtual_link]':{
          url: required_tour_link,
        }
      }
    })
  }
  $(document).find("#create_payment_form_tab").validate({
    rules:{
      "date[full_name]":{
        required: true
      },
      "date[card_number]":{
        required: true,
        valid_card: true,
        creditcard:true
      },
      "date[exp_month]":{
        required: true
      },
      "date[exp_year]":{
        required:true
      },
      "date[card_cvv]":{
        required:true,
        minlength: 3,
        maxlength: 3,
        valid_cvv: true
      },
      "date[billing_name]":{
        required:true
      },
      "date[billing_address]":{
        required:true
      },
      "date[zipcode]":{
        required:true,
        new_maxlength: 7
      },
      "date[city]":{
        required:true
      }
      ,
      "date[country]":{
        required:true
      }
    },
    messages:{
      "date[full_name]":{
        required: validate_full_name
      },
      "date[card_number]":{
        required: validate_card
      },
      "date[exp_month]":{
        required: requierd_expiry_month
      },
      "date[exp_year]":{
        required: requierd_expiry_year
      },
      "date[card_cvv]":{
        required: required_cvv,
        minlength: validate_cvv_minlength,
        maxlength: validate_cvv_maxlength
      },
      "date[billing_name]":{
        required: required_billing_name
      },
      "date[billing_address]":{
        required: required_billing_address
      },
      "date[zipcode]":{
        required: required_zipcode,
        new_maxlength: validate_zipcode_max
      },
      "date[city]":{
        required: validate_required_city
      },
      "date[country]":{
        required: validate_required_country
      }
    }
  })
  validate_property()
  validate_property_detail()
  validate_property_gallery()
})

if(window.location.pathname.includes('add_listing')){
  $(window).scrollTop(0);
}


   //return value == "" || value.length <= len;
// });

  // $("#property-gallery-images-dropzone").dropzone({
  //   url: "/property_gallery_images_uploads",
  //   init: function() {
  //     alert('cds')
  //     this.on("sending", function(file, xhr, formData) {
  //       formData.append("property_id", $("#property_id").val() );
  //       console.log(formData)
  //     });
  //   }
  // });
 //
 //  Dropzone.options.propertyGalleryImagesDropzone = {
 //    url: "/property-gallery-images-dropzone",
 //    paramName: "gallery_image", // The name that will be used to transfer the file
 //    init: function () {
 //      this.on("sending", function(file, xhr, data) {
 //                        data.append("filetype", "avataruploadtype");
 //                    });
 //    }
 // };
 // <div class='dz-progress'>
 //   <span class="dz-upload data-dz-uploadprogress"></span>
 // </div>
