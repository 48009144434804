import consumer from "./consumer"
var user_chat = "";
const initChatroomCable = () => {


       user_chat = consumer.subscriptions.create({
          channel: 'ChatChannel',
          chat_room_id: parseInt($(".message_chat_room_id").val())
      }, {
          connected: function () {
            //  console.log("Chat socket connected");
          },
          disconnected: function () {
              //console.log("Chat socket disconnected");
          },
          received: function (data) {
              //console.log("Chat socket data received");
              //console.log($('.current_user_id').html());
              //$(".custom-scroll").mCustomScrollbar('destroy');
              //$('.hhh').append(data['message']);
              $('.msg_'+data['current_chat_room_id']).append(data['message']);
              /*$(".custom-scroll").mCustomScrollbar({
                  theme:"dark",
                  scrollInertia: 200,
                  setTop:"-999999px"
              });*/
              // $('.common_class').addClass("others_message");
              $('.user_' + $('.current_user').val()).addClass("sent");
              $('.user_' + $('.current_user').val()).removeClass("replies");
              if (typeof $('.input_form_' + data['current_user_id'])[0] != 'undefined') {
                  $('.input_form_' + data['current_user_id'])[0].reset();
              }
              // document.getElementsByClassName('input_user_' + data['current_user_id'])[0].click();
              $(".chat-history").scrollTop($(".chat-history")[0].scrollHeight);
              //update_user_list($(".current_user_id").html());


          }
      })





}
export { initChatroomCable };
