import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'map' , 'marker']
  // initialize() {
  //   geocoder = new google.maps.Geocoder();
  // }
  connect() {
     //alert('cds')
     this._array_marker = {}
     this._mouseOverInfoWindow = false;
    if (typeof (google) != "undefined"){
      this._new_infowindow = new google.maps.InfoWindow({maxWidth: 300,
      maxHeight: 100,pixelOffset: new google.maps.Size(140, 120),disableAutoPan: true });

      this.multipleMarkedMap()
      //console.log(this.getLocation())
      var map_element =$(document).find('.map_reload')
    //  google.maps.event.addDomListener(window, 'load', );
      this.load_current_address()
      map_element.on('click',this.reload_controller.bind(this))
      google.maps.event.addListener(this.map(), 'click', function(){
        this._new_infowindow.close()
        this.info_marker.setIcon({url: this.normal_icon_url,
        scaledSize : new google.maps.Size(10, 10)})
      }.bind(this));
      google.maps.event.addListener(this.map(), 'tilesloaded', this.load_zoomed_property.bind(this));
      google.maps.event.addListener(this._new_infowindow, 'domready', function () {
        console.log(this._mouseOverInfoWindow)
        property_prime_slider()
        //console.log($("#gm_content").parent().parent().parent())
         // $("#gm_content").parent().parent().parent().mouseout(function(){
         //   this._new_infowindow.close()
         //   this.mouseOverInfoWindow = false;
         // }.bind(this));
         // $("#gm_content").parent().parent().parent().mouseover(function(){
         //  //  if (this.timeoutID) clearTimeout(this.timeoutID);
         //    this.mouseOverInfoWindow = true;
         //    console.log("inside="+this.mouseOverInfoWindow)
         //  }.bind(this));
      }.bind(this));
    }

  }

  load_zoomed_property(){

      var allowedBounds = this.map().getBounds();
      var maxLat = allowedBounds.getNorthEast().lat();
      var maxLng = allowedBounds.getNorthEast().lng();
      var minLat = allowedBounds.getSouthWest().lat();
      var minLng = allowedBounds.getSouthWest().lng();
      $("#filter_maxLat").val(maxLat)
      $("#modal_maxLat").val(maxLat)
      $("#sort_maxLat").val(maxLat)
      $("#filter_maxLng").val(maxLng)
      $("#modal_maxLng").val(maxLng)
      $("#sort_maxLng").val(maxLng)
      $("#filter_minLat").val(minLat)
      $("#modal_minLat").val(minLat)
      $("#sort_minLat").val(minLat)
      $("#filter_minLng").val(minLng)
      $("#modal_minLng").val(minLng)
      $("#sort_minLng").val(minLng)
      map_list_val = 2
      $("#sort_map_list_view").val(map_list_val)
      $("#filter_map_list_view").val(map_list_val)
      $("#map_list_view").val(map_list_val)
      // $("#filter_search_property_form").submit()
      $(document).find('.search_property_button')[0].click()
      // var map_list_view = $("#sort_map_list_view").val()
      // var min_price = $("#sort_min_price").val()
      // var max_price = $("#sort_max_price").val()
      // var property_list_type = $("#property_list_type").val()
      // var property_address = $("#sort_property_address").val()
      // var bedroom = $("#sort_bedroom").val()
      // var bathroom = $("#sort_bedroom").val()
      // var property_details = $("#sort_property_details").val()
      // var property_status = $("#sort_property_status").val()
      // var property_type = $("#sort_property_type").val()
      // var property_status_type_id = $("#sort_property_status_type_id").val()
      // var sort_order = $("#filter_sort_order").val()

      // $.ajax({
      //   url: "/properties/boundary_search_property",
      //   type: "get",
      //   data: {maxLat: maxLat, maxLng: maxLng, minLat: minLat, minLng: minLng, map_list_view: map_list_view, min_price: min_price, max_price: max_price, property_list_type: property_list_type, property_address: property_address, bedroom: bedroom, bathroom: bathroom, property_details: property_details, property_status: property_status, property_type: property_type ,property_status_type_id: property_status_type_id,sort_order: sort_order  },
      //   success: function(response){
      //     if(response["status"]){
      //
      //     }
      //   }
      // })




  }

  load_current_address(){
    var address = $("#filter_property_address").val()

    if(address != '' || address != undefined ){
      //console.log(address)
      var geocoder = new google.maps.Geocoder();
      geocoder.geocode( { 'address': address}, function(results, status) {
        // console.log(results)
          if (status == google.maps.GeocoderStatus.OK) {
              this.map().setCenter(results[0].geometry.location);
              //this.map().setZoom(10)
             //this.map().fitBounds(results[0].geometry.viewport);
//console.log(results[0].geometry.viewport)
          } else {
              //alert("Geocode was not successful for the following reason: " + status);
          }
      }.bind(this));
    }
  }

  reload_controller(){
    //this._new_infowindow = new google.maps.InfoWindow({maxWidth: 300,maxHeight: 100});
    this.multipleMarkedMap()
  }
  multipleMarkedMap(){
      this.map()
      var array_marker_length= Object.keys(this._array_marker).length
      if(array_marker_length > 0 ){
        for (let i = 0; i < array_marker_length ; i++) {
          this._array_marker[i+1].setMap(null);
        }
        this._array_marker = {}
      }

    //console.log(this.markerTargets)
     // var bounds = new google.maps.LatLngBounds();
    this.markerTargets.forEach((element, index) => {
      var lang = element.getAttribute("data-lang")
      var lat = element.getAttribute("data-lat")
      var icon_image = element.getAttribute('data-icon_url')
      var focus_icon_image = element.getAttribute('data-focus_icon_url')
      var location =  element.getAttribute('data-location')
      var new_parent_element = ''
     var data_marker_id  = element.getAttribute('data-marker-id')
    //  console.log(icon_image)
      // if(data_marker_id == null){
        if($(element).parents('.property_map_hover')[0] != undefined){
          var parent_element = $(element).parents('.property_map_hover')[0].innerHTML
          new_parent_element = `<div id='gm_content' class='map-hover-box'>${parent_element}</div>`
        }
        if(lang != '' && lat != ''){
          var mapLocation = new google.maps.LatLng(lat,lang)
          //console.log(mapLocation)

          var _marker=this.marker(mapLocation,icon_image)
          var array_length = Object.keys(this._array_marker).length
          // console.log(array_length)
          this._array_marker[array_length+1] = _marker
          element.setAttribute('data-marker-id',array_length+1)

          // bounds.extend(mapLocation);
          if(new_parent_element!=''){
            google.maps.event.addListener(_marker, 'mouseover', this.infowindow.bind(this,new_parent_element, _marker,focus_icon_image,icon_image));
            google.maps.event.addListener(_marker, 'click', this.infowindow.bind(this,new_parent_element, _marker,focus_icon_image,icon_image));

          }
          // google.maps.event.addListener(_marker, 'mousemove', function(e){
          //   console.log(e)
          // })
          google.maps.event.addListener(_marker, 'mouseout', function(e){

            console.log(!this._mouseOverInfoWindow)
           //this._new_infowindow.close()
          // setTimeout(function() {
          //   // console.log(this)
          //   this._new_infowindow.close()
          // }.bind(this), 4000);
          this.timeoutID = setTimeout(function() {
          if (!this._mouseOverInfoWindow) {
            this._new_infowindow.close()
          }
        }.bind(this), 500);
            // this._closeInfoWindowWithTimeout = setTimeout(function() { this._new_infowindow.close()}.bind(this), 1000);
             _marker.setIcon({url: icon_image,scaledSize : new google.maps.Size(10, 10)});
          }.bind(this,_marker,icon_image));
        }
      // }


    })
    // console.log(this._bounds)
    // this.map().fitBounds(bounds)

  }

  infowindow(parent_element,marker,focus_icon,icon_image){
    //  clearTimeout(this.closeMarkerInfoWithTimeout)
    if (this.timeoutID) clearTimeout(this.timeoutID);
    marker.setIcon({url: focus_icon,
    scaledSize : new google.maps.Size(17, 17)}); // focus line avail
    this._new_infowindow.setOptions({ pixelOffset: this.getInfowindowOffset(this.map(), marker) });
    this._new_infowindow.setContent(parent_element);
    this._new_infowindow.open(this.map(),marker);
    this.info_marker = marker
    this.focus_icon_url = focus_icon
    this.normal_icon_url = icon_image
    this.addOpenInfoWindowListeners();
      property_prime_slider()

      //this.map().setZoom(10);
  }

  map() {
    if(this._map == undefined) {
      // var location=this.getLocation()
      var strictBounds = ""
      var map_bound = ""
      var filter_maxLat = $("#filter_maxLat").val()
      var filter_maxLng = $("#filter_maxLng").val()
      var filter_minLat= $("#filter_minLat").val()
      var filter_minLng= $("#filter_minLng").val()
      if(filter_maxLat && filter_maxLng && filter_minLat && filter_minLng){
        map_bound  = new google.maps.LatLngBounds(
        new google.maps.LatLng(filter_maxLat, filter_maxLng),           // top left corner of map
        new google.maps.LatLng(filter_minLat, filter_minLng)            // bottom right corner
        );
      }

      this._map = new google.maps.Map(this.mapTarget, {
          center: new google.maps.LatLng(Default_Lat,Default_Long),
          zoom: 9,
          mapTypeControl: false,
          mapTypeId: google.maps.MapTypeId.ROADMAP ,
          panControl: false,
          streetViewControl: false,
          scaleControl: false,
          rotateControl: false,
      })
    }

    //this.map().fitBounds(this._bounds)
    return this._map
  }

  marker(mapLocation,icon_image) {
    var new_icon_image = {
                    url: icon_image,
                    scaledSize : new google.maps.Size(10, 10),
                };
      var marker = new google.maps.Marker({
        map: this.map(),

        icon: new_icon_image,
      })

      marker.setPosition(mapLocation)
      marker.setVisible(true)
      return marker
  }
  // geocodePosition(pos)
  // {
  //    var geocoder = new google.maps.Geocoder();
  //    console.log(geocoder)
  //    geocoder.geocode
  //     ({
  //         latLng: pos
  //     },
  //         (results, status)=>
  //         {
  //             console.log(results)
  //             if (status == google.maps.GeocoderStatus.OK)
  //             {
  //
  //               console.log(results)
  //               var location = results[0].formatted_address
  //               return location
  //               //  $("#mapErrorMsg").hide(100);
  //             }
  //             else
  //             {
  //               return ''
  //                 //$("#mapErrorMsg").html('Cannot determine address at this location.'+status).show(100);
  //             }
  //         }
  //     );
  // }

  gethoverindetail(_event){
      var element = _event.target
        //console.log(element)
      var parent_element = $(element).parents('.property_map_hover')

      var container = $(parent_element)[0]
      // console.log(container)
      var lat_lang_element = $(parent_element).find('.get_lat_lan_class')

      var lang = lat_lang_element.attr("data-lang")
      var lat = lat_lang_element.attr("data-lat")
      var icon_image = lat_lang_element.attr('data-icon_url')
      var focus_icon = lat_lang_element.attr("data-focus_icon_url")
      if(lang != '' && lat != ''){
        var mapLocation = new google.maps.LatLng(lat,lang)
        var marker= lat_lang_element.attr("data-marker-id")

        if(this._new_infowindow != undefined) {
                this._new_infowindow.close();
        }
        // console.log(container != undefined)

        if(container != undefined && container.innerHTML != undefined){


        this._new_infowindow = new google.maps.InfoWindow({
          content: `<div id='gm_content' class='map-hover-box'>${container.innerHTML}</div>`,
          // position: mapLocation,
          maxWidth: 300,
          maxHeight: 100,
          disableAutoPan: true
        //  pixelOffset: new google.maps.Size(140, 120),
        });
        this._new_infowindow.setOptions({ pixelOffset: this.getInfowindowOffset(this.map(), this._array_marker[marker]) });
        this._array_marker[marker].setIcon({url: focus_icon,
        scaledSize : new google.maps.Size(17, 17)})  // focus line avail
        this.info_marker = this._array_marker[marker]
        this._new_infowindow.open(this.map(),this._array_marker[marker]);


      }

      }

  }

  closeinfowindow(_event){
    var element = _event.target
    var parent_element = $(element).parents('.property_map_hover')
    var container = $(parent_element)[0]
    var lat_lang_element = $(parent_element).find('.get_lat_lan_class')
    var icon = lat_lang_element.attr("data-icon_url")
    var lang = lat_lang_element.attr("data-lang")
    var lat = lat_lang_element.attr("data-lat")
    var marker= lat_lang_element.attr("data-marker-id")
    this._new_infowindow.close()
    if(lang != '' && lat != '', this._array_marker[marker] != undefined){
      //this._array_marker[marker].setIcon(null)
      this._array_marker[marker].setIcon({url: icon,
      scaledSize : new google.maps.Size(10, 10)})
    }
    //marker.setIcon(focus_icon);
  }


  getLocation() {

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(this.successFunction.bind(this),this.errorFunction.bind(this))
  } else {

    var lat= Default_Lat
    var lan  = Default_Long
    this._lat_lan = new google.maps.LatLng(lat,lng)

    return this.lat_lan
  }
}

successFunction(position) {

    if (position != undefined) {
    var lat = position.coords.latitude;
    var lng = position.coords.longitude;
    this._lat_lan =  new google.maps.LatLng(this._lat,this._long)}
    else{
      var lat = Default_Lat;
      var lng = Default_Long;
      this._lat_lan =  new google.maps.LatLng(this._lat,this._long)
      return  this._lat_lan
    }
    return  this._lat_lan
}
errorFunction(){
  var lat = Default_Lat;
  var lng = Default_Long;
  this._lat_lan =  new google.maps.LatLng(this._lat,this._long)
  return  this._lat_lan
}

getInfowindowOffset(map, marker) {
        var center = this.getPixelFromLatLng(map.getCenter()),
            point = this.getPixelFromLatLng(marker.getPosition()),

            quadrant = "",
            offset;

        quadrant += (point.y > center.y) ? "b" : "t";
        quadrant += (point.x < center.x) ? "l" : "r";
          // console.log(quadrant)
        if (quadrant == "tr") {
            offset = new google.maps.Size(-150, 300);
        } else if (quadrant === "tl") {
            offset = new google.maps.Size(150, 300);
        } else if (quadrant === "br") {
            offset = new google.maps.Size(-150, 20);
        } else if (quadrant === "bl") {
            offset = new google.maps.Size(150, 20);
        }
        return offset;
    };

    getPixelFromLatLng(latLng) {
        var projection = this.map().getProjection();
        // console.log(projection)
        var point = projection.fromLatLngToPoint(latLng);
        return point;
    };
//
  addOpenInfoWindowListeners() {
    //$("#gm_content").parent().parent().parent()
  var infoWindowElement = document.querySelector('.gm-style-iw-c')
  // console.log(infoWindowElement)
if (infoWindowElement != null){
  infoWindowElement.addEventListener('mouseleave', function() {
    this._new_infowindow.close()
    this._mouseOverInfoWindow = false;
    this.info_marker.setIcon({url: this.normal_icon_url,
    scaledSize : new google.maps.Size(10, 10)})

  }.bind(this));

  infoWindowElement.addEventListener('mouseover', function() {
    this._mouseOverInfoWindow = true;
    this.info_marker.setIcon({url: this.focus_icon_url,
    scaledSize : new google.maps.Size(17, 17)}) // focus line avail

  }.bind(this));
}
}

}
