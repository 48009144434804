//image
require.context("../images",true)

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
// require("channels")
import "controllers"


//scss
import 'bootstrap';
// import 'simplebar';
import "@fortawesome/fontawesome-free/css/all";
// import 'simplebar/dist/simplebar.css';
// import 'dropzone/dist/dropzone.css';
// import 'cropperjs/dist/cropper.css';
// import '../../assets/stylesheets/social-share-button.scss'
// import 'multiple-select/dist/multiple-select.min.css';
// import "../css/frontend/common.scss";
// import "jquery-datetimepicker/jquery.datetimepicker.css";
// import "../css/jquery.raty.css";
//end css and scss

var jQuery = require('jquery')
// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
global.Rails = Rails
window.Rails = Rails
global.Default_Lat = 18.466224
window.Default_Lat = 18.466224
global.Default_Long = -66.1057244
window.Default_Long = -66.1057244

// global.$.fn = global.jQuery.fn = jQuery;
// window.$.fn = window.jQuery.fn = jQuery;
import { createPopper } from '@popperjs/core';
require("popper.js")
// ActionCable
import { initChatroomCable } from '../channels/chat_channel';
import { check_online } from '../channels/appearance_channel';
import { update_user_list } from '../channels/user_list_channel';
import Modal from 'bootstrap/js/src/modal'

window.initChatroomCable = initChatroomCable
global.initChatroomCable = initChatroomCable
require('jquery-cropper')
require('dropzone')
require("slick-carousel")
require("jquery-validation")
require("slick-lightbox")
require("./js/jquery.raty")
// import {selectCountry} from "./js/frontend/stripe_country"
//require('bootstrap-multiselect')
// require('./js/frontend/map')
require("jquery.payment")
require("jquery-inputmask")
require("./js/frontend/property")
require("./js/frontend/profile")
require("./js/frontend/contact_us")

 // require("./footer_common")
// require("./js/en_validation_messages")
// require("./js/es_validation_messages")
require("multiple-select/dist/multiple-select.js")
require("jquery-datetimepicker")
require("./js/common")
import Cropper from 'cropperjs'
window.Cropper = Cropper
require('./social-share-button.js.erb')
require("./footer_common")


// console.log(document.getElementById('property_gallery_property_gallery_images'))
// .js file



window.initAutocomplete = function(...args){
  const event = document.createEvent('Events');
  event.initEvent("google-map-search",true,true);
  event.args = args
  window.dispatchEvent(event);

}

window.removeParam = function(key, sourceURL) {
    var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
}
window.geturlparameter = function(sParam){
  var sPageURL = window.location.search.substring(1);
  var sURLVariables = sPageURL.split('&');
  for (var i = 0; i < sURLVariables.length; i++)
  {
      var sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] == sParam)
      {
          return decodeURIComponent(sParameterName[1]);
      }
  }
}

window.show_delete_confirmation_map_reset_modal = function(){
  if($("#delete_confirmation_map_reset").length > 0){
    $("#delete_confirmation_map_reset").modal('show')
  }

}

window.hide_delete_confirmation_map_reset_modal = function(){
  if($("#delete_confirmation_map_reset").length > 0){
    $("#delete_confirmation_map_reset").modal('hide')
  }
}

window.hide_upgrade_plan_modal = function(){
  if($("#show_upgrade_modal").length > 0){
    $("#show_upgrade_modal").modal('hide')
  }
  if($("#property_done").length > 0){
    $("#property_done").modal('hide')
     window.location.href = window.location.origin+'/properties/property_list';
  }
}

window.property_prime_slider =  function(){
  //property-slider
  $('.prime-slider, .map-prime-slider, .map-exclusive-slider, .exclusive-slider').not('.slick-initialized').slick({
      dots: true,
      prevArrow: '<button class="slick-prev prev-arrow icon"></button>',
      nextArrow: '<button class="slick-next next-arrow icon"></button>'
  });
}
window.reload_property_prime_slider =  function(){
  //property-slider
  $('.prime-slider, .map-prime-slider, .map-exclusive-slider, .exclusive-slider').slick('refresh');
}
// window.setflash_time = function(){
// setTimeout(function() {
//   $('.alert').hide();
// }, 3000);
// }

$(document).on('turbolinks:load', function() {
  initChatroomCable()
  check_online()
  update_user_list()
  property_prime_slider()

})
