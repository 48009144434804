$(document).on('turbolinks:load', function() {

	$.validator.addMethod("textOnly", function (value, element) {
           var pattern = /^[a-zA-Z\s]+$/
           return this.optional(element) || pattern.test(value);
     });

	jQuery.validator.addMethod("initialtrimFunc", function(value, element){
      val = value.replace(/^\s+/g, "");
      return this.optional(element) || val == value;
}, "Please remove extra space.");

    //alert("jjbjb")

   $(document).find('form#contact_us_form').validate({
        rules: {
            'contact[name]': {
                required: true,
                minlength: 2,
                maxlength: 50,
                textOnly: true,
                initialtrimFunc:  true,
            },
            'contact[email]': {
                required: true,
                minlength: 2,
                maxlength: 100,
                initialtrimFunc:  true,
            },
						'contact[phone]': {
                required: true,
                minlength: 10,
                maxlength: 10,
                initialtrimFunc:  true,
            },
            'contact[message]': {
            	required: true,
                minlength: 5,
                maxlength: 1000,
               initialtrimFunc:  true,
            },
        },
        messages: {
            'contact[name]': {
                required: validate_lead_full_name,
                minlength: validate_name_minlength,
                maxlength: validate_name_maxlength,
                textOnly: validate_text_only,
            },
            'contact[email]': {
                required: validate_lead_email,
                minlength: email_minlength,
                maxlength: validate_email_maxlength,
            },
						'contact[phone]': {
                required: validate_user_phone_number,
                minlength: phone_minlength,
                maxlength: phone_maxlength,
            },
            'contact[message]': {
            	required: required_message,
                minlength: validate_messages_minlength,
                maxlength: validate_messages_maxlength,
            }
        }
    })
		recaptch_form()
   function recaptch_form(){
   	///alert("hjhj")
  var recaptcha_form=$(document).find(".my_captcha_form")[0]
      var version = $('.check_version').data('version')
      if(typeof recaptcha_form != 'undefined' && version == 'v2') {


          $('.my_captcha_form').on("submit", function (evt) {
              var response = grecaptcha.getResponse();
              console.log(response.length == 0)
              if (response.length == 0) {
                  //reCaptcha not verified
                  console.log(response.length)

                  $(document).find('#recaptcha_message-error').html(validate_captcha);
                  $(document).find('#recaptcha_message-error').show();
                  console.log($('#recaptcha_message-error'))
                  evt.preventDefault();
                  return false;
              }else{
                  $(document).find('#recaptcha_message-error').html("");
                  $(document).find('#recaptcha_message-error').hide();
              }
              //captcha verified
              //do the rest of your validations here

          });
      }
    }

		$(document).on('submit',"#contact_us_form",function(e){
			$(document).find(".loader-outer").removeClass("d-none")
			$(document).find(".loader-outer").addClass("d-block")
		})


})
