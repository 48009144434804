import consumer from "./consumer"
const check_online = ()  =>{
    consumer.subscriptions.create("AppearanceChannel", {
        connected: function(){
          //  console.log("appearance socket connected");
        },
        disconnected: function(){
          //  console.log("appearance socket disconnected");
        },
        received: function(data){
          //  console.log(data['online_user_ids']);
            var is_online = $('.is_online');
            $.each(is_online, function( index, value ) {
                if(data['online_user_ids'].includes(parseInt(value.id))){
                    $(value).html('<span class="user-status active"></span>');
                } else {
                    $(value).html('<span class="user-status"></span>');
                }
            });
        }
    })
}
export { check_online };
