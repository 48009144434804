// if (file){
//   let reader = new FileReader();
//   reader.onload = function(event){
//
//     $('#profile_image_id').attr('src', event.target.result);
//     //$('#profile_image_crop_id').attr('src', event.target.result);
//
//   }
//   reader.readAsDataURL(file);
// }

//$(document).on('turbolinks:load', function() {

  $(document).on('change','#user_profile_image',function(e){
        file = this.files[0];


          $('.modal-dialog').addClass('modal-lg');
          var tmppath = URL.createObjectURL(e.target.files[0]);
          //$('#profile_image_id').attr('src', tmppath);
          //$('#profile_image_crop_id').fadeIn().attr('src', tmppath);
          var extension = $(this).val().split('.').pop().toLowerCase();
          // Create array with the files extensions that we wish to upload
          var validFileExtensions = ['jpeg', 'jpg', 'png'];
          //Check file extension in the array.if -1 that means the file extension is not in the list.
        //  console.log($.inArray(extension, validFileExtensions) == -1)
          if ($.inArray(extension, validFileExtensions) == -1) {
            // alert('cds')
          $('#user_profile_image-error').html("Please upload jpg, jpeg, png file only.")
          $('#user_profile_image-error').show();
          // Clear fileuload control selected file
          //$(this).replaceWith($(this).val('').clone(true));

          }
          else {
            var $image =   $("img#profile_image_crop_id").fadeIn().attr('src',tmppath),
                      cropBoxData,
                      canvasData;
            $(document).find('#cropModal').modal({show: true, backdrop: 'static',keyboard: false})
            $('#cropModal').on('shown.bs.modal', function () {
            $image.cropper({
                  strict: true,
                  guides: true,
                  highlight: true ,
                  dragCrop: true,
                  cropBoxMovable: true,
                  cropBoxResizable: true,
                  modal: true,
                  dragMode:"none",
                  // data:{
                  //     width: 500,
                  //     height:  500,
                  // }
                  built: function () {
                      canvasData = $image.cropper('getCanvasData');
                      var cropBox = {
                          width : 200,
                          height : 200
                      }
                  $image.cropper('setCropBoxData', cropBox);
                  $image.cropper('setCanvasData', canvasData);

                  }
              });

            }).submit('#crop_form', function (e) {
                  e.preventDefault()
                  cropBoxData = $image.cropper('getCropBoxData');
                  canvasData = $image.cropper('getCanvasData');
                  var getData = $image.cropper('getData');
                  $('#width').val(getData.width);
                  $('#height').val(getData.height);
                  $('#x').val(getData.x);
                  $('#y').val(getData.y);
                  var files = file;
                  var formData = new FormData();
                  formData.append('x',$('#x').val())
                  formData.append('width',$('#width').val())
                  formData.append('height',$('#height').val())
                  formData.append('y',$('#y').val())
                  formData.append('profile_image', file, file.name);
                  $(document).find(".loader-outer").removeClass("d-none")
                  $(document).find(".loader-outer").addClass("d-block")
                  $.ajax({
                    url: "/users/crop_image",
                    contentType: false,
                    cache: false,
                    processData: false,
                    type: 'POST',
                    dataType: 'json',
                    data: formData,
                    mimeType: 'multipart/form-data',
                    headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                    },
                    success:  function(response){
                      if(response['status']){

                        $image.cropper('destroy');
                        $('#cropModal').modal('hide');
                        $('.main_profile_box').load(window.location.href + ' .inner_profile_box')
                        $('.main_image_load_container').load(window.location.href + ' .inner_image_load_container', function(){

                          $(document).find('#crop_image_submit').attr('disabled',false)
                          $(document).find(".loader-outer").addClass("d-none")
                          $(document).find(".loader-outer").removeClass("d-block")
                        })


                      }else{
                        $(document).find('#crop_image_submit').attr('disabled',false)
                      }
                    }
                  })
              });
            }
      })

  $(document).on('change','#user_company_company_logo',function(e){
            file = this.files[0];
             $('.modal-dialog').addClass('modal-lg');
            var extension = $(this).val().split('.').pop().toLowerCase();
            // Create array with the files extensions that we wish to upload
            var validFileExtensions = ['jpeg', 'jpg', 'png'];
            //Check file extension in the array.if -1 that means the file extension is not in the list.
            console.log($.inArray(extension, validFileExtensions) == -1)
            if ($.inArray(extension, validFileExtensions) == -1) {

            $(document).find('#user_company_company_logo-error').html("Please upload jpg, jpeg, png file only.")
            $(document).find('#user_company_company_logo-error').show();
            // Clear fileuload control selected file
            //$(this).replaceWith($(this).val('').clone(true));

            }
            else {
              var tmppath = URL.createObjectURL(e.target.files[0]);
              //$('#profile_image_id').attr('src', tmppath);
              //$('#profile_image_crop_id').fadeIn().attr('src', tmppath);
              var $image =   $("img#profile_company_crop_id").fadeIn().attr('src',tmppath),
                        cropBoxData,
                        canvasData;
              $(document).find('#cropCompanyModal').modal({show: true, backdrop: 'static',keyboard: false})
              $('#cropCompanyModal').on('shown.bs.modal', function () {
              $image.cropper({
                    strict: true,
                  guides: true,
                  highlight: true ,
                  dragCrop: true,
                  cropBoxMovable: true,
                  cropBoxResizable: true,
                  modal: true,
                  dragMode:"none",
                  // data:{
                  //     width: 500,
                  //     height:  500,
                  // },
                  built: function () {
                      canvasData = $image.cropper('getCanvasData');
                      var cropBox = {
                          width : 200,
                          height : 200
                      }
                  $image.cropper('setCropBoxData', cropBox);
                  $image.cropper('setCanvasData', canvasData);

                  }
                });
              }).submit('#crop_company_form', function (e) {
                    e.preventDefault()
                    cropBoxData = $image.cropper('getCropBoxData');
                    canvasData = $image.cropper('getCanvasData');
                    var getData = $image.cropper('getData');
                    $('#crop_width').val(getData.width);
                    $('#crop_height').val(getData.height);
                    $('#crop_x').val(getData.x);
                    $('#crop_y').val(getData.y);
                    var files = file;
                    var formData = new FormData();
                    formData.append('crop_x',$('#crop_x').val())
                    formData.append('crop_width',$('#crop_width').val())
                    formData.append('crop_height',$('#crop_height').val())
                    formData.append('crop_y',$('#crop_y').val())
                    formData.append('company_image', file, file.name);
                    $(document).find(".loader-outer").removeClass("d-none")
                    $(document).find(".loader-outer").addClass("d-block")
                    $.ajax({
                      url: "/users/crop_company_image",
                      contentType: false,
                      cache: false,
                      processData: false,
                      type: 'POST',
                      dataType: 'json',
                      data: formData,
                      mimeType: 'multipart/form-data',
                      headers: {
                      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                      },
                      success:  function(response){
                        if(response['status']){
                          $('#cropCompanyModal').modal('hide');
                          $image.cropper('destroy');
                          $('.main_company_load_container').load(window.location.href + ' .inner_company_load_container',function(){
                            $(document).find(".loader-outer").addClass("d-none")
                            $(document).find(".loader-outer").removeClass("d-block")
                          })
                          $(document).find('#crop_company_submit').attr('disabled',false)
                        }else{
                          $(document).find('#crop_company_submit').attr('disabled',false)
                        }
                      }
                    })
                });

              }
          })

//})//end of turbolinks
