import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["addressinput"]

  connect() {

  //  console.log('connected')
    if (typeof (google) != "undefined"){
      // console.log(this.addressinputTarget)
      this.initiateAutocomplete()
      // var map_element =$(document).find('#open_filter_modal')
      // map_element.on('click',this.reload_controller.bind(this))
    }
  }
  reload_controller(){
    //console.log('open')
    // $(document).find('#filter-modal').modal('show')
    //this._new_infowindow = new google.maps.InfoWindow({maxWidth: 300,maxHeight: 100});
    this.initiateAutocomplete()
  }
  initiateAutocomplete(){
    // if (this._autocomplete == 'undefined') {
      // console.log(this.addressinputTarget)
      this._autocomplete = new google.maps.places.Autocomplete(this.addressinputTarget)
      this._autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
      this._autocomplete.addListener('place_changed', this.locationChanged.bind(this))
    // }
    return this._autocomplete
  }

  locationChanged() {
    let place = this.initiateAutocomplete().getPlace()
    // console.log(place)
    if (place!= undefined && !place.geometry) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      window.alert("No details available for input: '" + place.name + "'");
      return;
    }
    if (place!= undefined){
      // console.log(place)
      this.property_address_inputTarget.value = place.name
      var sort_id = this.property_address_inputTarget.dataset.change_id
      $(sort_id).val(place.name)
    }

  }
}
