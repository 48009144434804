function settime_to_hide(){
    setTimeout(function() {
      $('.alert').hide();
    }, 10000);
}
 settime_to_hide()
 $(document).on('click', ".rate_agent_button", function(e){
   e.preventDefault();
   var agent_rating = $(".rating_of_agent")
   var score = $(document).find('input[name="rate_agent_score"]').val()
   var old_score =$(document).find(".rating_of_agent").attr("data-old_score")
   //console.log(score)
   old_score = old_score != "" ? old_score : "0"
   var this_tag = $(this)
   this_tag.prop('disabled', true);
   $.ajax({
       url: "/rate_agent",
       type: "get",
       data: {agent_id: $("#agent_agent_id").val(), rating: score },
       headers: {
       'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
       },
       success: function(response){
         $(document).find("#rate_agent_modal").modal('hide')
         if(response["status"]){
           agent_rating.raty('setScore', score);
           if(response['total_agent_rate']){
             $(document).find('.update_total_agent_rate').html('<i class="fas fa-star"></i>'+response['total_agent_rate'])
           }

           $(document).find(".rating_of_agent").attr("data-old_score",score)
           // $(document).find('#ajax_flash').show().html("").html('<div class="slide-effect"><div class="alert alert-success alert-dismissible"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button><div class="alert-message p-med"><i class=" fa fa-check"></i>Success!</div>'+response["message"]+'</div></div>');
           settime_to_hide()

         }else {

           if(response["not_user_presence"]){
             if($(document).find(".signin_popup").length > 0 ){
               $(document).find(".signin_popup")[0].click()
             }
           }
           //console.log(old_score)
             $(document).find(".rating_of_agent").attr("data-old_score",old_score)
         //  $(document).find('input[name="rate_agent_score"]').val(old_score)
             agent_rating.raty('setScore', old_score);
             $(document).find('#ajax_flash').show().html("").html('<div class="slide-effect"><div class="alert alert-danger alert-dismissible"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button><div class="alert-message p-med"><i class=" fa fa-ban"></i>Alert!</div>'+response["message"]+'</div></div>');
             settime_to_hide()
         }
         this_tag.prop('disabled', false);
       }
     })
 })
$(document).on('turbolinks:load', function() {
  var total_agent_rating = $(".total_rating_of_agent").attr("data-score");
  $('.total_rating_of_agent').raty({ scoreName:  total_agent_rating, half  : true,size:       24, starHalf: '/assets/star-half.png', starOff: '/assets/star-off.png', starOn:  '/assets/star-on.png', readOnly : true });


function raty_function(){
  var agent_rating = $(".rating_of_agent")
  //  console.log(agent_rating)

  if(agent_rating.length > 0){
    var data_score = agent_rating.attr("data-score");
  //console.log(data_score)

    $('.rating_of_agent').raty({ score: function() { return data_score;},scoreName: "rate_agent_score", half  : true,size:       24, starHalf: '/assets/star-half-big.png', starOff: '/assets/star-off-big.png', starOn:  '/assets/star-on-big.png',click: function(score,e){
      var return_val =  true
      var score = Math.round(score*2)/2;
      //console.log(score)
    //
    // return false;
  }
  });
  }
} //function end
raty_function()



// check if compare page than remove all comparission
if( window.location.pathname.includes('compare/property_view_comparison')){
  var remove_all_button = $(document).find("#remove_all_property_id_from_compare")
  if(remove_all_button.length > 0){
    remove_all_button.click()
  }
}

// check if compare page than remove all comparission
if( window.location.pathname.includes('compare/agent_view_comparison')){
  var remove_all_button = $(document).find("#remove_all_agent_id_from_compare")
  if(remove_all_button.length > 0){
    remove_all_button.click()
  }
}

var  check_get_agent_local_storage_array = JSON.parse(localStorage.getItem("agent_ids_array"))
var  check_get_property_local_storage_array = JSON.parse(localStorage.getItem("property_ids_array"))
// if(get_agent_local_storage_array)
if(check_get_agent_local_storage_array == null){
  check_get_agent_local_storage_array = [];
}
if(check_get_property_local_storage_array == null){
  check_get_property_local_storage_array = [];
}

if((check_get_agent_local_storage_array.length > 0) || (check_get_property_local_storage_array.length > 0)) {

  $.ajax({
    url: "/show_agent_property_data_update",
    type: "post",
    data: {agent_ids_array: check_get_agent_local_storage_array, property_ids_array:  check_get_property_local_storage_array },
    success: function(response){
      //console.log(response)
      if(response['status']){
        $(document).find('.main_agent_property_pop').show()
        if(check_get_property_local_storage_array.length > 0){
          $(document).find('.add_property_detail_to_pop').html(response['property_html'])
          $(document).find('.inner_property_pop').show()

        }else{
          $(document).find('.inner_property_pop').hide()
        //  console.log($(document).find('.inner_property_pop'))
        }
        if(check_get_agent_local_storage_array.length > 0){
          $(document).find('.add_agent_detail_to_pop').html(response['agent_html'])
          $(document).find('.inner_agent_pop').show()
        }else{
          $(document).find('.inner_agent_pop').hide()
        }
      }else{
        $(document).find('.main_agent_property_pop').hide()
        $(document).find('.inner_property_pop').hide()
        $(document).find('.inner_agent_pop').hide()
      }
    }
  })

}else{
  $(document).find('.main_agent_property_pop').hide()
  $(document).find('.inner_property_pop').hide()
  $(document).find('.inner_agent_pop').hide()
}


  if( window.location.pathname.includes('agent/agent_detail')){

    class_agent = $(document).find(".agent-flexSwitchCheckDefault")
    var  get_agent_local_storage_array = JSON.parse(localStorage.getItem("agent_ids_array"))
    if(get_agent_local_storage_array == null){
      get_agent_local_storage_array = [];
    }
    if( class_agent.length > 0){
      class_agent.each(function () {
        if(get_agent_local_storage_array.includes($(this).attr('data-agent_id'))){
          $(this).prop("checked", true)
        }
      })
    }

  }

  if( (window.location.pathname.includes('properties/search_property')) || (window.location.pathname.includes('properties/view_property_detail'))){
    class_property = $(document).find(".property_flexSwitchCheckDefault")
    var  get_property_local_storage_array = JSON.parse(localStorage.getItem("property_ids_array"))
    // if(get_agent_local_storage_array)
    //console.log($(document).find("#agent-web-flexSwitchCheckDefault"))
    if(get_property_local_storage_array == null){
      get_property_local_storage_array = [];
    }
    if( class_property.length > 0){
      class_property.each(function () {
        if(get_property_local_storage_array.includes($(this).attr('data-property_id'))){
          $(this).prop("checked", true)
        }
      })
    }
  }
})
//turbolinks end

  $(document).on('change','.agent-flexSwitchCheckDefault',function(e){
    var agent_id = $(this).attr("data-agent_id")
    var  get_agent_local_storage_array = JSON.parse(localStorage.getItem("agent_ids_array"))

    if(this.checked){
      // if(get_agent_local_storage_array)
      if(get_agent_local_storage_array == null){
        get_agent_local_storage_array = [];
      }

    //  console.log(get_agent_local_storage_array.length < 3)
      if( (get_agent_local_storage_array.length < 3)){
        if((jQuery.inArray(agent_id, get_agent_local_storage_array) == -1)){
          get_agent_local_storage_array.push(agent_id)
          localStorage.setItem('agent_ids_array', JSON.stringify(get_agent_local_storage_array));
          show_agent_property_data_update(get_agent_local_storage_array, '')
        }
      }
      else{
        this.checked = false
        $('#ajax_flash').show().html("").html('<div class="slide-effect"><div class="alert alert-danger alert-dismissible"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>You can select only three agent to compare </div></div>');
        setTimeout(function() {
          $('.alert').hide();
        }, 10000);

      }
  }else{
    //console.log(jQuery.inArray(agent_id, get_agent_local_storage_array))
    if(!(jQuery.inArray(agent_id, get_agent_local_storage_array) == -1)){

      var data_index = get_agent_local_storage_array.indexOf(agent_id)
      // console.log(data_index)

      get_agent_local_storage_array.splice(data_index, 1);
      //console.log(get_agent_local_storage_array)
      localStorage.removeItem('agent_ids_array');
      localStorage.setItem('agent_ids_array', JSON.stringify(get_agent_local_storage_array));
      show_agent_property_data_update(get_agent_local_storage_array, '')
    }
  }
  })


  // $(document).ready(function(){

  // })

  $(document).on('click', ".property_flexSwitchCheckDefault",function(e){
    property_id = $(this).attr("data-property_id")
    var  get_property_local_storage_array = JSON.parse(localStorage.getItem("property_ids_array"))
      if(this.checked){
        // if(get_agent_local_storage_array)
        if(get_property_local_storage_array == null){
          get_property_local_storage_array = [];
        }

        //console.log(get_agent_local_storage_array)
        if( (get_property_local_storage_array.length < 3)){
          if((jQuery.inArray(property_id, get_property_local_storage_array) == -1)){
            get_property_local_storage_array.push(property_id)
            localStorage.setItem('property_ids_array', JSON.stringify(get_property_local_storage_array));
            show_agent_property_data_update("", get_property_local_storage_array)
          }
        }
        else{
          this.checked = false
          $('#ajax_flash').show().html("").html('<div class="slide-effect"><div class="alert alert-danger alert-dismissible"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button> '+warning_to_select_compare_button_length_global_+' </div></div>');
          setTimeout(function() {
            $('.alert').hide();
          }, 100000);

        }
    }else{
      //console.log(jQuery.inArray(property_id, get_property_local_storage_array))
      if(!(jQuery.inArray(property_id, get_property_local_storage_array) == -1)){

        var data_index = get_property_local_storage_array.indexOf(property_id)
        // console.log(data_index)

        get_property_local_storage_array.splice(data_index, 1);
        //console.log(get_agent_local_storage_array)
        localStorage.removeItem('property_ids_array');
        localStorage.setItem('property_ids_array', JSON.stringify(get_property_local_storage_array));
        show_agent_property_data_update('', get_property_local_storage_array)
      }
    }
  })

  $(document).on('click','a.remove_agent_id_from_compare',function(e){
    e.preventDefault();
    //console.log($(this))
    var agent_id = $(this).attr("data-agent-id")
    //console.log(agent_id)
    var  get_agent_local_storage_array = JSON.parse(localStorage.getItem("agent_ids_array"))
    if(!(jQuery.inArray(agent_id, get_agent_local_storage_array) == -1)){

      var data_index = get_agent_local_storage_array.indexOf(agent_id)
      class_agent = $(document).find(".agent-flexSwitchCheckDefault")

      if( class_agent.length > 0){
        class_agent.each(function(){
          //var pr_id = $(this).attr("data-property_id")
        //console.log(get_property_local_storage_array.includes(property_id))
          if(get_agent_local_storage_array.includes(agent_id)){
            $(this).prop("checked", false)
          }
        })
      }
      get_agent_local_storage_array.splice(data_index, 1);
      //console.log(get_agent_local_storage_array)
      localStorage.removeItem('agent_ids_array');
      localStorage.setItem('agent_ids_array', JSON.stringify(get_agent_local_storage_array));
      show_agent_property_data_update(get_agent_local_storage_array, '')

    }
  })

  $(document).on('click','a.remove_property_id_from_compare',function(e){
    e.preventDefault();
    //console.log($(this))
    // $(document).find(".loader-outer").removeClass("d-none")
    // $(document).find(".loader-outer").addClass("d-block")
    var property_id = $(this).attr("data-property_id")

    var  get_property_local_storage_array = JSON.parse(localStorage.getItem("property_ids_array"))
    if(!(jQuery.inArray(property_id, get_property_local_storage_array) == -1)){

      var data_index = get_property_local_storage_array.indexOf(property_id)
      //console.log(property_id)
      class_property = $(document).find(".property_flexSwitchCheckDefault")

      if( class_property.length > 0){
        class_property.each(function(){
          //var pr_id = $(this).attr("data-property_id")
        //console.log(get_property_local_storage_array.includes(property_id))
          if(get_property_local_storage_array.includes(property_id)){
            $(this).prop("checked", false)
          }
        })
      }
      get_property_local_storage_array.splice(data_index, 1);
      //console.log(get_agent_local_storage_array)
      localStorage.removeItem('property_ids_array');
      localStorage.setItem('property_ids_array', JSON.stringify(get_property_local_storage_array));
      show_agent_property_data_update('', get_property_local_storage_array)
      // $(document).find(".loader-outer").removeClass("d-block")
      // $(document).find(".loader-outer").addClass("d-none")

    }
  })

  $(document).on('click','#remove_all_agent_id_from_compare',function(e){

    localStorage.removeItem('agent_ids_array');
    var  get_agent_local_storage_array = JSON.parse(localStorage.getItem("agent_ids_array"))
    //show_agent_property_data_update(get_agent_local_storage_array, '')
    $(document).find('.inner_agent_pop').hide()
    if($(document).find("#agent-mob-flexSwitchCheckDefault").length > 0){
      $(document).find("#agent-mob-flexSwitchCheckDefault").prop("checked", false)
    }
    //console.log($(document).find("#agent-web-flexSwitchCheckDefault"))
    if($(document).find("#agent-web-flexSwitchCheckDefault").length > 0){
      $(document).find("#agent-web-flexSwitchCheckDefault").prop("checked", false)
    }

  })
  $(document).on('click','#remove_all_property_id_from_compare',function(e){

    localStorage.removeItem('property_ids_array');
    var  get_agent_local_storage_array = JSON.parse(localStorage.getItem("property_ids_array"))
    //show_agent_property_data_update(get_agent_local_storage_array, '')
    $(document).find('.inner_property_pop').hide()
    if($(document).find(".property_flexSwitchCheckDefault").length > 0){
      $(document).find(".property_flexSwitchCheckDefault").prop("checked", false)
    }


  })
 function show_agent_property_data_update(agent,property){
   $.ajax({
     url: "/show_agent_property_data_update",
     type: "post",
     beforeSend: function() {
       $(document).find(".loader-outer").removeClass("d-none")
       $(document).find(".loader-outer").addClass("d-block")
            },
     data: {agent_ids_array: agent , property_ids_array: property },
     success: function(response){
       //console.log(response)
       $(document).find(".loader-outer").removeClass("d-block")
       $(document).find(".loader-outer").addClass("d-none")
       if(response['status']){
           $(document).find('.main_agent_property_pop').show()

         if(agent !== ''){

           $(document).find('.add_agent_detail_to_pop').html(response['agent_html'])
           $(document).find('.inner_agent_pop').show()
         }
         if(property !== ''){

           $(document).find('.add_property_detail_to_pop').html(response['property_html'])
           $(document).find('.inner_property_pop').show()
         }

       }else{
         // $(document).find('.main_agent_property_pop').hide()
         // $(document).find('.inner_property_pop').hide()
         if(agent !== ''){
           $(document).find('.inner_agent_pop').hide()
         }
         if(property !== ''){
           $(document).find('.inner_property_pop').hide()
         }

       }
     }
   })
 }

 $(document).on('click',".rating_hide_show", function(e){
   if($(".rating_of_agent").hasClass('hide_class')){
     $(".rating_of_agent").show()
     $(".rating_of_agent").addClass('.show_class')
     $(".rating_of_agent").removeClass('hide_class')
   }else{
     $(".rating_of_agent").hide()
     $(".rating_of_agent").addClass('hide_class')
     $(".rating_of_agent").removeClass('show_class')
   }
 })

 $(document).on('change', ".on_change_sort_order", function(e){
   e.preventDefault();
   // console.log($('#filter_property_address').val())
   // console.log($('#sort_property_address').val())
   // console.log($("#filter_search_property_form").validate())
   // console.log($("#filter_search_property_form").valid() +' '+ $("#modal_search_property_form").valid())

   if($('#sort_property_address').val() != '' ){
       $(document).find(".loader-outer").removeClass("d-none")
       $(document).find(".loader-outer").addClass("d-block")
       $('#filter_sort_order').val($(this).val())
       $('#modal_sort_order').val($(this).val())
       $("#submit_hidden_sort_form").trigger('click')
   }else{

     //  console.log($(document).find('#filter-modal'))
     // console.log($(document).find('#filter-modal #modal_property_address-error'))
     // console.log($(document).find('#filter_property_address-error'))
     $(document).find('#filter-modal #modal_property_address-error').html('Please enter city, place or zipcode').show()
     $(document).find('#filter_property_address-error').html('Please enter city, place or zipcode').show()
     //$('#open_filter_modal').modal('show')
     return false
   }
   // form = $(this).closest('form')
   // jQuery.rails.fire(form, 'submit')
   //form.dispatchEvent(new Event('submit', {bubbles: true}));
 })
